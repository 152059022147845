import { Box, Link, styled } from "@mui/material";

const CabalGroupRowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(1, 1),
  border: `1px solid ${theme.palette.border.dark}`,
  background: theme.palette.surface.silver,
  borderRadius: theme.spacing(1.5),
  alignItems: "center",
}));

const OwnerImage = styled("img")(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.border.light}`,
  objectFit: "cover",
  objectPosition: "center",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  all: "unset",
  cursor: "pointer",
}));

export { CabalGroupRowContainer, OwnerImage, StyledLink };
