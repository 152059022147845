import { ColorType, CrosshairMode } from "lightweight-charts";
import { BasicChart, IChartConfig } from "./BasicChart";
import theme from "../../theme";
import { useChart } from "hooks/useChart";

const activeConfigs: IChartConfig = {
  base: {
    layout: {
      background: {
        type: ColorType.Solid,
        color: theme.palette.surface.silver,
      },
      textColor: theme.palette.text["btn-dark"],
    },
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    rightPriceScale: {
      visible: false,
    },
    leftPriceScale: {
      visible: false,
    },
    localization: {
      timeFormatter: (time: any) => {
        return time.toString();
      },
    },
    timeScale: {
      tickMarkFormatter: (time: any) => {
        return time.toString();
      },
      visible: false,
      fixLeftEdge: true,
      fixRightEdge: true,
      lockVisibleTimeRangeOnResize: true,
    },
    crosshair: {
      vertLine: {
        visible: false,
      },
      horzLine: {
        visible: false,
      },
      mode: CrosshairMode.Hidden,
    },
    height: 0,
  },
  series: [
    {
      id: "steepness",
      area: {
        lineColor: theme.palette.brand.primary,
        topColor: theme.palette.brand.primary,
        bottomColor: theme.palette.surface.silver,
        crosshairMarkerVisible: false,
        priceLineVisible: false,
        lastValueVisible: false,
      },
      line: {
        color: theme.palette.brand.primary,
        crosshairMarkerVisible: false,
        priceLineVisible: false,
        lastValueVisible: false,
      },
    },
  ],
};

const nonActiveConfigs: IChartConfig = {
  base: {
    layout: {
      background: {
        type: ColorType.Solid,
        color: theme.palette.surface.silver,
      },
      textColor: theme.palette.text["btn-dark"],
    },
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    rightPriceScale: {
      visible: false,
    },
    leftPriceScale: {
      visible: false,
    },
    localization: {
      timeFormatter: (time: any) => {
        return time.toString();
      },
    },
    timeScale: {
      tickMarkFormatter: (time: any) => {
        return time.toString();
      },
      visible: false,
      fixLeftEdge: true,
      fixRightEdge: true,
      lockVisibleTimeRangeOnResize: true,
    },
    crosshair: {
      vertLine: {
        visible: false,
      },
      horzLine: {
        visible: false,
      },
      mode: CrosshairMode.Hidden,
    },
    height: 0,
  },
  series: [
    {
      id: "steepness",
      area: {
        lineColor: theme.palette.text.secondary,
        topColor: "#cccccccc",
        bottomColor: "#595E65",
        crosshairMarkerVisible: false,
        priceLineVisible: false,
        lastValueVisible: false,
      },
      line: {
        color: theme.palette.text.secondary,
        crosshairMarkerVisible: false,
        priceLineVisible: false,
        lastValueVisible: false,
      },
    },
  ],
};

export const PreviewChart = ({
  data,
  variant = "active",
}: {
  variant?: "non-active" | "active";
  data: Array<any>;
}) => {
  const { chartRef, seriesRef, setIsMounted } = useChart();

  return (
    <BasicChart
      data={data}
      configs={variant === "active" ? activeConfigs : nonActiveConfigs}
      chartRef={chartRef}
      seriesRef={seriesRef}
      setIsMounted={setIsMounted}
    />
  );
};
