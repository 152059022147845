import { Box, Theme, styled } from "@mui/material";

export type Variant = "primary" | "white" | "dark" | "darker" | "green" | "red";

const SURFACE_COLOR = ({ theme }: { theme: Theme }) => ({
  primary: theme.palette.brand.primary,
  white: theme.palette.surface.light,
  dark: theme.palette.surface.silver,
  darker: theme.palette.surface.container,
  green: theme.palette.system.green,
  red: theme.palette.system.red,
});

const SURFACE_BACKGROUND_COLOR = ({ theme }: { theme: Theme }) => ({
  primary: theme.palette.brand["primary-bg"],
  white: theme.palette.border.light,
  dark: theme.palette.surface["container"],
  darker: theme.palette.surface["container-bg"],
  green: theme.palette.system["green-bg"],
  red: theme.palette.system["red-bg"],
});

const TEXT_COLOR = ({ theme }: { theme: Theme }) => ({
  primary: theme.palette.text.secondary,
  white: theme.palette.text.secondary,
  dark: theme.palette.text.secondary,
  darker: theme.palette.text.secondary,
  green: theme.palette.text["system-green"],
  red: theme.palette.text["system-red"],
});

const RADIUS = "8px";

const BrickWrapper = styled(Box)({
  paddingTop: "7px",
  width: "fit-content",
  lineHeight: "normal",
});

const BrickContainer = styled(Box)<{
  variant: Variant;
}>(({ theme, variant }) => ({
  backgroundColor: SURFACE_BACKGROUND_COLOR({ theme })[variant],
  borderRadius: RADIUS,
  border: `1px solid ${theme.palette.border.dark}`,
  position: "relative",

  "&:after": {
    content: '""',
    position: "absolute",
    left: RADIUS,
    right: RADIUS,
    height: "20px",
    bottom: 0,
    borderLeft: `1px solid ${theme.palette.border.dark}`,
    borderRight: `1px solid ${theme.palette.border.dark}`,
  },
}));

const BrickInner = styled("div")<{
  variant: Variant;
}>(({ theme, variant }) => ({
  padding: theme.spacing(0.5, 1),
  backgroundColor: SURFACE_COLOR({ theme })[variant],
  position: "relative",
  zIndex: "2",
  top: "-7px",
  border: `1px solid ${theme.palette.border.dark}`,
  margin: "0 -1px",
  borderRadius: RADIUS,
  color: TEXT_COLOR({ theme })[variant],
}));

export { BrickContainer, BrickInner, BrickWrapper };
