import { Box, Theme, styled } from "@mui/material";

type Variant = "primary" | "white" | "dark" | "disabled";
type Size = "sm" | "md";

type VariantColor = {
  [key in Variant]: string;
};

const SURFACE_COLOR = (theme: Theme): VariantColor => ({
  primary: theme.palette.brand.primary,
  white: theme.palette.surface.light,
  disabled: theme.palette.surface.silver,
  dark: theme.palette.surface.container,
});

const SURFACE_BACKGROUND_COLOR = (theme: Theme): VariantColor => ({
  primary: theme.palette.brand["primary-bg"],
  white: theme.palette.border.light,
  disabled: theme.palette.surface["container-bg"],
  dark: theme.palette.surface["container-bg"],
});

const TEXT_COLOR = (theme: Theme): VariantColor => ({
  primary: theme.palette.text.brand,
  white: theme.palette.text["btn-dark"],
  disabled: theme.palette.text.secondary,
  dark: theme.palette.text.secondary,
});

const RADIUS: Record<Size, string> = {
  sm: "8px",
  md: "12px",
};

const DisabledButton = styled("button")<{ size: Size }>(({ theme, size }) => ({
  all: "unset",
  backgroundColor: theme.palette.surface.silver,
  width: "100%",
  height: "auto",
  borderRadius: RADIUS[size],
  border: `1px solid ${theme.palette.border.dark}`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const RetroButtonWrapper = styled(Box)<{ size?: Size }>(({ size }) => ({
  width: size === "sm" ? "auto" : "100%",
  height: size === "sm" ? "auto" : "100%",
  paddingTop: "7px",
}));

const RetroButtonContainer = styled(Box)<{
  variant: Variant;
  disabled?: boolean;
  size: Size;
}>(({ theme, variant, disabled, size }) => ({
  backgroundColor: SURFACE_BACKGROUND_COLOR(theme)[variant],
  borderRadius: RADIUS[size],
  width: "100%",
  height: "100%",
  border: `1px solid ${theme.palette.border.dark}`,
  position: "relative",

  "&:after": {
    content: '""',
    position: "absolute",
    left: RADIUS[size],
    right: RADIUS[size],
    height: "20px",
    bottom: 0,
    borderLeft: `1px solid ${theme.palette.border.dark}`,
    borderRight: `1px solid ${theme.palette.border.dark}`,
  },

  ...(disabled && {
    backgroundColor: theme.palette.surface.container,
  }),
}));

const RetroButtonInner = styled("button")<{
  variant: Variant;
  isClicking: boolean;
  animationDuration: number;
  disabled?: boolean;
  size: Size;
}>(({ theme, variant, isClicking, animationDuration, disabled, size }) => ({
  all: "unset",
  backgroundColor: SURFACE_COLOR(theme)[variant],
  width: "100%",
  height: "100%",
  position: "relative",
  borderRadius: RADIUS[size],
  border: `1px solid ${theme.palette.border.dark}`,
  zIndex: "2",
  top: "-7px",
  margin: "0 -1px",
  transition: `top ${animationDuration}ms ease-in-out`,
  color: TEXT_COLOR(theme)[variant],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& svg": {
    color: TEXT_COLOR(theme)[variant],
  },

  ...(isClicking && {
    top: "-2px",
  }),

  ...(disabled && {
    backgroundColor: theme.palette.surface.silver,
    color: theme.palette.text.secondary,
    top: "-2px",
    margin: "-1px",

    "& svg": {
      color: theme.palette.text.secondary,
    },
  }),
}));

export { RetroButtonContainer, RetroButtonInner, RetroButtonWrapper, DisabledButton };
