import { Box, styled, Theme, Typography } from "@mui/material";

type ToastVariant = "success" | "error" | "info";

const BACKGROUND_COLOR: (theme: Theme) => Record<ToastVariant, string> = (theme: Theme) => ({
  success: theme.palette.system.green,
  error: theme.palette.system.red,
  info: theme.palette.brand.primary,
});

const TEXT_COLOR: (theme: Theme) => Record<ToastVariant, string> = (theme: Theme) => ({
  success: theme.palette.text["system-green"],
  error: theme.palette.text["system-red"],
  info: theme.palette.text["primary"],
});

const ToastContainer = styled(Box)<{ variant: ToastVariant }>(({ theme, variant }) => ({
  background: BACKGROUND_COLOR(theme)[variant],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  color: TEXT_COLOR(theme)[variant],

  transition: "height 0.3s ease-in-out",
  "& a": {
    color: TEXT_COLOR(theme)[variant],
    textDecoration: "underline",
  },
}));

const StyledContent = styled("div")<{ variant: ToastVariant }>(({ theme, variant }) => ({
  color: TEXT_COLOR(theme)[variant],
  ...theme.typography["body-default"],
}));

const Animated = styled(Box)({
  opacity: 0,
  height: 0,
  transition: "all 0.3s ease-in-out",
  transformOrigin: "left top",
  transform: "translateY(-100%)",
  position: "fixed",
  left: 0,
  right: 0,

  visibility: "hidden",
  zIndex: 2,
  "&.open": {
    transform: "translateY(0)",
    opacity: 1,
    height: "auto",
    visibility: "visible",
  },
});

const HeaderToast = ({
  isOpen,
  variant,
  message,
}: {
  isOpen: boolean;
  variant: ToastVariant;
  message?: string;
}) => {
  const Content = () => (
    <ToastContainer variant={variant}>
      <StyledContent
        variant={variant}
        dangerouslySetInnerHTML={{
          __html: message ?? "",
        }}></StyledContent>
    </ToastContainer>
  );
  return (
    <>
      <Animated className={isOpen ? "open" : ""}>
        <Content />
      </Animated>
      {/* Spacer */}
      {isOpen && (
        <Box sx={{ opacity: 0 }}>
          <Content />
        </Box>
      )}
    </>
  );
};

export { HeaderToast };
