// TODO: Mock Data
export const mockAssets = [
  {
    id: 1,
    iconUri: `https://picsum.photos/id/23/40/40`,
    chain: "ton",
    balance: 123.45,
    tokenType: "SLERF",
    usdValue: 123.45,
    percentage: 0.7,
  },
  {
    id: 2,
    iconUri: "https://picsum.photos/id/53/40/40",
    chain: "solana",
    balance: 23.45,
    tokenType: "solana",
    usdValue: 23.45,
    percentage: 0.7,
  },
  {
    id: 3,
    iconUri: "https://picsum.photos/id/67/40/40",
    chain: "ethereum",
    balance: 13.45,
    tokenType: "ETH",
    usdValue: 13.45,
    percentage: 0.7,
  },
  {
    id: 4,
    iconUri: "https://picsum.photos/id/87/40/40",
    chain: "base",
    balance: 1.45,
    tokenType: "SCERF",
    usdValue: 1.45,
    percentage: -0.7,
  },
  {
    id: 5,
    iconUri: "https://picsum.photos/id/123/40/40",
    chain: "ton",
    balance: 1.45,
    tokenType: "SCERF",
    usdValue: 1.45,
    percentage: 0.7,
  },
];

export const mockEarnings = [
  {
    id: 1,
    iconUri: `https://picsum.photos/id/23/40/40`,
    chain: "ton",
    balance: 0.45,
    tokenType: "SLERF",
    usdValue: 0.45,
    percentage: 0.7,
  },
  {
    id: 2,
    iconUri: "https://picsum.photos/id/53/40/40",
    chain: "solana",
    balance: 0.45,
    tokenType: "solana",
    usdValue: 0.45,
    percentage: 0.7,
  },
  {
    id: 3,
    iconUri: "https://picsum.photos/id/67/40/40",
    chain: "ethereum",
    balance: 0.45,
    tokenType: "ETH",
    usdValue: 0.45,
    percentage: 0.7,
  },
  {
    id: 4,
    iconUri: "https://picsum.photos/id/87/40/40",
    chain: "base",
    balance: 0.45,
    tokenType: "SCERF",
    usdValue: 0.45,
    percentage: -0.7,
  },
  {
    id: 5,
    iconUri: "https://picsum.photos/id/123/40/40",
    chain: "ton",
    balance: 0.45,
    tokenType: "SCERF",
    usdValue: 0.45,
    percentage: 0.7,
  },
];

export const mockGroups = [
  {
    groupId: 1,
    iconUri: `https://picsum.photos/id/77/40/40`,
    name: "New Cabals",
    usdValue: 2.45,
    percentage: 0.7,
  },
  {
    groupId: 1,
    iconUri: `https://picsum.photos/id/55/40/40`,
    name: "Chill",
    usdValue: 3.315,
    percentage: -0.7,
  },
  {
    groupId: 1,
    iconUri: `https://picsum.photos/id/65/40/40`,
    name: "Comfy",
    usdValue: 5.15,
    percentage: 0.5,
  },
  {
    groupId: 1,
    iconUri: `https://picsum.photos/id/24/40/40`,
    name: "Surger Cabals",
    usdValue: 2.15,
    percentage: 1.7,
  },
  {
    groupId: 1,
    iconUri: `https://picsum.photos/id/179/40/40`,
    name: "Ton Land",
    usdValue: 11.45,
    percentage: -0.7,
  },
];
