import { atom } from "recoil";

type TransactionStoreState = {
  tokenAddress?: string;
  walletAddress?: string;
};

const transactionStoreAtom = atom<TransactionStoreState>({
  key: "transactionStoreAtom",
  default: {
    tokenAddress: undefined,
    walletAddress: undefined,
  },
});

export default transactionStoreAtom;
