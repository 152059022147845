import React, { useEffect, useRef } from "react";
import {
  AreaSeriesPartialOptions,
  ChartOptions,
  createChart,
  DeepPartial,
  LineSeriesPartialOptions,
} from "lightweight-charts";

// TODO: Refactoring
export interface BasicChartProps {
  data: Array<any>;
  configs: IChartConfig;
  chartRef: React.MutableRefObject<any>;
  seriesRef: React.MutableRefObject<any>;
  chartContainerRef?: React.MutableRefObject<any>;
  tooltip?: React.ReactNode;
  tooltipRef?: React.MutableRefObject<any>;
  setIsMounted: (isMounted: boolean) => void;
}

export interface IChartConfig {
  base: DeepPartial<ChartOptions>;
  series: Array<IChartSeriesConfig>;
}

export interface IChartSeriesConfig {
  id: string;
  area?: AreaSeriesPartialOptions;
  line?: LineSeriesPartialOptions;
}

export const BasicChart = (props: BasicChartProps) => {
  const { data, configs, chartRef, seriesRef, tooltipRef, tooltip, setIsMounted } = props;

  const { base, series } = configs;

  const localChartContainerRef = useRef<HTMLDivElement>(null);

  const chartContainerRef = props.chartContainerRef || localChartContainerRef;

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
        });
      }
    };

    const handleClick = () => {
      if (tooltipRef?.current && tooltipRef.current.style.display !== "none") {
        tooltipRef.current!.style.display = "none";
      }
    };

    if (chartContainerRef.current) {
      // Create the chart
      chartRef.current = createChart(chartContainerRef.current, base);

      // Add the series
      for (let i = 0; i < series.length; i++) {
        const { id, area, line } = series[i];

        seriesRef.current = {};
        seriesRef.current[id] = {};
        if (series[i]) {
          seriesRef.current[id]["area"] = chartRef.current.addAreaSeries({
            width: chartContainerRef.current.clientWidth,
            ...area,
          });
          seriesRef.current[id]["area"].setData(data);
        }

        if (series[i]) {
          seriesRef.current[id]["line"] = chartRef.current.addLineSeries(line);
          seriesRef.current[id]["line"].setData(data);
        }
      }

      chartRef.current.timeScale().fitContent();
      chartRef.current.timeScale().offset = 0;
      chartRef.current.subscribeClick(handleClick);
      window.addEventListener("resize", handleResize);
      setIsMounted(true);
    }

    return () => {
      if (tooltipRef && tooltipRef.current) {
        tooltipRef.current.style.display = "none";
      }
      window.removeEventListener("resize", handleResize);
      chartRef.current.unsubscribeClick(handleClick);
      chartRef.current.remove();
      setIsMounted(false);
    };
  }, [data, ...series]);

  return (
    <div
      style={{ position: "relative", width: "100%", height: "100%" }}
      onMouseLeave={() => {
        if (tooltipRef?.current) {
          tooltipRef.current.style.display = "none";
        }
      }}
      onBlur={() => {
        if (tooltipRef && tooltipRef.current && tooltipRef.current.style.display !== "none") {
          tooltipRef.current.style.display = "none";
        }
      }}>
      <div
        ref={chartContainerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      <div ref={tooltipRef} />
      {tooltip}
    </div>
  );
};
