import { useState } from "react";
import { RetroButtonContainer, RetroButtonInner, RetroButtonWrapper } from "./styled";

const RetroButton = ({
  children,
  variant = "primary",
  size = "md",
  shouldBounce = true,
  disabled = false,
  animationDuration = 200,
  ...props
}: React.HTMLAttributes<HTMLButtonElement> & {
  variant?: "primary" | "white" | "dark" | "disabled";
  shouldBounce?: boolean;
  disabled?: boolean;
  animationDuration?: number;
  size?: "sm" | "md";
}) => {
  const [isClicking, setIsClicking] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    setIsClicking(true);
    setTimeout(() => {
      props.onClick?.(e);
      if (shouldBounce) {
        setIsClicking(false);
      }
    }, animationDuration);
  };

  return (
    <RetroButtonWrapper size={size}>
      <RetroButtonContainer variant={variant} disabled={disabled} size={size}>
        <RetroButtonInner
          as="button"
          animationDuration={animationDuration}
          variant={variant}
          onClick={handleClick}
          isClicking={isClicking}
          disabled={disabled}
          size={size}>
          {children}
        </RetroButtonInner>
      </RetroButtonContainer>
    </RetroButtonWrapper>
  );
};

export { RetroButton };
