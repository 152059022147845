import { Variant } from "./styled";
import { BrickContainer, BrickInner, BrickWrapper } from "./styled";

const Brick = ({
  children,
  variant = "primary",
}: {
  children: React.ReactNode;
  variant?: Variant;
}) => {
  return (
    <BrickWrapper>
      <BrickContainer variant={variant}>
        <BrickInner variant={variant}>{children}</BrickInner>
      </BrickContainer>
    </BrickWrapper>
  );
};

export { Brick };
