import { useEffect } from "react";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { PortfolioContainer } from "./styled";
import { WalletDetail } from "./WalletDetail";
import { Profile } from "./Profile";
import { RetroCard } from "components/RetroCard";
import { Box } from "@mui/material";
import { PortfolioDetail } from "./PortfolioDetail";

const Portfolio = () => {
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  useEffect(() => {
    if (isShowHeader) {
      setIsShowHeader(false);
    }

    return () => {
      setIsShowHeader(true);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "13px",
        width: "100%",
        paddingTop: "20px",
      }}>
      <Box sx={{ width: "100%" }}>
        <RetroCard size="sm">
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}>
            <PortfolioContainer>
              <Profile />
              <WalletDetail />
            </PortfolioContainer>
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        <RetroCard size="sm">
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}>
            <PortfolioContainer>
              <PortfolioDetail />
            </PortfolioContainer>
          </Box>
        </RetroCard>
      </Box>
    </Box>
  );
};

export { Portfolio };
