import { Theme } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material" {
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Palette {
    text: {
      primary: string;
      secondary: string;
      brand: string;
      disabled: string;
      "system-green": string;
      "system-red": string;
      "btn-dark": string;
    };
    // Background
    brand: {
      primary: string;
      "primary-bg": string;
    };
    system: {
      green: string;
      "green-bg": string;
      red: string;
      "red-bg": string;
    };
    border: {
      dark: string;
      light: string;
    };
    surface: {
      light: string;
      "light-bg": string;
      silver: string;
      container: string;
      bg: string;
      "container-bg": string;
    };
  }

  interface PaletteOptions {
    text?: {
      primary?: string;
      secondary?: string;
      brand?: string;
      disabled?: string;
      "system-green"?: string;
      "system-red"?: string;
      "btn-dark"?: string;
    };
    // Background
    brand?: {
      primary?: string;
      "primary-bg"?: string;
    };
    system?: {
      green?: string;
      "green-bg"?: string;
      red?: string;
      "red-bg"?: string;
    };
    border?: {
      dark?: string;
      light?: string;
    };
    surface?: {
      light?: string;
      "light-bg"?: string;
      silver?: string;
      container?: string;
      bg?: string;
      "container-bg"?: string;
    };
  }
}

// Colors
const light = {
  100: "#FFFFFF",
  200: "#CCCCCC",
  300: "#D4D4D4",
};

const dark = {
  100: "#595E65",
  200: "#373C44",
  400: "#24272C",
  500: "#212327",
  600: "#0B0B0B",
};

const orange = {
  300: "#FFC949",
  500: "#AE7A00",
  700: "#6D4D02",
};

const green = {
  300: "#D9FCA2",
  500: "#26894F",
  700: "#0D3F22",
};

const red = {
  300: "#FCACA2",
  500: "#893526",
  700: "#3F150D",
};

// A custom theme for this app
const theme = createTheme({
  palette: {
    // TODO: Old theme to be removed
    primary: {
      main: "#FFB800",
      contrastText: "#000",
    },
    secondary: {
      main: "#313855",
    },
    error: {
      main: "#ef5350",
    },
    warning: {
      main: "#FF5147",
    },
    action: {
      active: "#ffffff",
      hover: "#00000",
      disabledBackground: "#B2B2B2",
    },
    // Text
    text: {
      primary: light[100],
      secondary: light[300],
      brand: orange[700],
      disabled: dark[100],
      "system-green": green[300],
      "system-red": red[300],
      "btn-dark": dark[600],
    },
    // Background
    brand: {
      primary: orange[300],
      "primary-bg": orange[700],
    },
    system: {
      green: green[500],
      "green-bg": green[700],
      red: red[500],
      "red-bg": red[700],
    },
    border: {
      dark: dark[600],
      light: light[300],
    },
    surface: {
      light: light[100],
      "light-bg": light[200],
      silver: dark[100],
      container: dark[200],
      bg: dark[400],
      "container-bg": dark[500],
    },
  },
  typography: {
    // Old typography to be removed
    fontFamily: "Cabin Condensed, sans-serif",
    button: {
      textTransform: "none",
    },
    // Custom typography
    "display-lg": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "64px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    "display-md": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "56px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    "display-sm": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "48px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    "title-h1": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "40px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    "title-h2": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "32px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    "title-h3": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    subtitle: {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "1.2em",
      letterSpacing: "-0.02em",
    },
    "body-lg": {
      fontFamily: "Aber Mono, sans-serif",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "1.4em",
    },
    "body-md": {
      fontFamily: "Aber Mono, sans-serif",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.4em",
    },
    "body-default": {
      fontFamily: "Aber Mono, sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1.4em",
    },
    "body-sm": {
      fontFamily: "Aber Mono, sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "1.4em",
    },
    "pre-title": {
      fontFamily: "Aber Mono, sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "1.4em",
      letterSpacing: "0.05em",
    },
    "pre-title-2": {
      fontFamily: "Aber Mono, sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "1.4em",
      letterSpacing: "0.05em",
    },
    "button-lg": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "1.2em",
    },
    "button-md": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.2em",
    },
    "button-sm": {
      fontFamily: "Edit Undo BRK, sans-serif",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "1.2em",
    },
  },
});

export default theme;
