import { Box, Typography, useTheme } from "@mui/material";
import { LoadingView } from "components/LoadingView";
import { EmptyView } from "components/EmptyView";
import { RetroCard } from "components/RetroCard";
import { ScrollableBox } from "components/ScrollableBox";
import { Select } from "components/Select";
import { useState } from "react";
import { TokenCallHeader, TokenCallRow } from "components/TokenCall";
import { DefaultLoginBlockerContent, LoginBlockerWrapper } from "components/LoginBlocker";
import Pagination from "components/Pagination";
import { Container } from "components/container";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import tradeImage from "assets/icons/trade.png";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { LoadMoreRow } from "components/LoadMoreRow";

const LOGIN_REDIRECT_URL = process.env.REACT_APP_TG_WIDGET_LOGIN_URL;

const UnauthorizedView = ({ login }: { login: () => void }) => {
  return (
    <RetroCard>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
        }}>
        <img src={tradeImage} alt="trade" />
        <Box
          sx={{
            textAlign: "center",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "24px",
          }}>
          <DefaultLoginBlockerContent login={login} />
        </Box>
      </Box>
    </RetroCard>
  );
};

const CallPage = () => {
  const theme = useTheme();
  const cabalSelectOptions = [
    {
      label: "All Cabals",
      value: "all",
    },
  ];
  const [selectedCabal, setSelectedCabal] = useState("all");
  const { tgUserId, tgUserName, walletAddresses } = useUserStoreV2();
  const { tonAddress } = walletAddresses || {};

  const [page, setPage] = useState(1);

  // const { data, isLoading } = useQuery({
  //   queryKey: ["cabal-calls", { page }],
  //   queryFn: async () => {
  //     return axiosService.getCabalCalls({
  //       limit: 10,
  //       page,
  //       selects: [
  //         "name",
  //         "symbol",
  //         "iconUri",
  //         "confidenceInterval",
  //         "createdAt",
  //         "updatedAt",
  //         "userId",
  //         "groupId",
  //         "chain",
  //         "jetton",
  //       ],
  //     });
  //   },
  // });

  const queryResult = useInfiniteQuery({
    queryKey: ["cabal-calls", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getCabalCalls({
        limit: 10,
        page: pageParam,
        selects: [
          "name",
          "symbol",
          "iconUri",
          "confidenceInterval",
          "createdAt",
          "updatedAt",
          "userId",
          "groupId",
          "chain",
          "jetton",
          "address",
        ],
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        window.open(LOGIN_REDIRECT_URL);
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // if (isLoading) {
  //   return <LoadingView />;
  // }

  if (!data || !data.pages[0].results || data.pages[0].results.length === 0) {
    return <EmptyView />;
  }

  return (
    <LoginBlockerWrapper
      isLoginedIn={!!tgUserId || !!tgUserName || !!tonAddress}
      fallback={<UnauthorizedView login={handleLogin} />}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1), height: "100%" }}>
        <Select
          value={selectedCabal}
          options={cabalSelectOptions}
          onChange={(event) => setSelectedCabal(event.target.value as string)}
        />
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: theme.spacing(2.5),
              gap: theme.spacing(1),
              height: "100%",
            }}>
            <Typography variant="title-h3" color={theme.palette.text.primary}>
              Historical Calls By Cabals
            </Typography>
            <TokenCallHeader />
            <ScrollableBox>
              {data.pages
                .flatMap((page) => page.results)
                .map((tokenCall: any) => (
                  <Box key={tokenCall._id} sx={{ marginTop: theme.spacing(0.5) }}>
                    <TokenCallRow tokenCall={tokenCall} />
                  </Box>
                ))}
              {!hasAllFetched && <LoadMoreRow ref={ref} />}
            </ScrollableBox>
            {/* <Container sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Pagination
                count={data.totalPages}
                page={page}
                onChange={handlePageChange}
                siblingCount={0}
              />
            </Container> */}
          </Box>
        </RetroCard>
      </Box>
    </LoginBlockerWrapper>
  );
};

export { CallPage };
