import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as SearchNormalIcon } from "assets/icons/search-normal.svg";

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.surface.container,
  borderRadius: "12px",
  width: "100%",

  "&:hover, &:focus": {
    border: `1px solid ${theme.palette.text.secondary}`,
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "& .MuiInputBase-input": {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ...theme.typography["body-md"],

    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },

  "& .MuiSvgIcon-root": {
    color: theme.palette.text.secondary,
  },
}));

const SearchField = (props: TextFieldProps) => {
  return (
    <StyledTextField
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchNormalIcon />
          </InputAdornment>
        ),
      }}
      inputProps={{
        notched: false,
      }}
      InputLabelProps={{ shrink: false }}
      placeholder="Search"
      {...props}
    />
  );
};

export { SearchField };
