import axios from "axios";

export type UserData = {
  userData: {
    user: {
      id: number;
    };
  };
};

let accessToken: string | null = null;
let refreshToken: string | null = null;
let refreshSubscribers: ((token: string) => void)[] = [];

const setTokens = (access: string | null, refresh: string | null) => {
  accessToken = access;
  refreshToken = refresh;
  if (!access || !refresh) {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  } else {
    localStorage.setItem("refreshToken", refresh);
    localStorage.setItem("accessToken", access);
  }
};

const getAccessToken = () => accessToken || localStorage.getItem("accessToken");

const refreshAccessToken = async () => {
  try {
    if (!refreshToken) {
      refreshToken = localStorage.getItem("refreshToken");
    }
    const response = await axios.post(process.env.REACT_APP_API_URL + "/v1/auth/refresh-tokens", {
      refreshToken,
    });

    const { tokens } = response.data;
    if (tokens) {
      setTokens(tokens.access.token, tokens.refresh.token);
    }

    return tokens.access.token;
  } catch (error) {
    // Handle refresh error (e.g., logout user)
    throw error;
  }
};

const tgAuth = async (userData: UserData) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/v1/utils/tg/auth",
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const { tokens } = response.data;
    if (tokens) {
      setTokens(tokens.access.token, tokens.refresh.token);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

const onRefreshed = (token: string) => {
  refreshSubscribers.forEach((callback) => callback(token));
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

const removeTokens = () => {
  setTokens(null, null);
};

export {
  setTokens,
  getAccessToken,
  refreshAccessToken,
  tgAuth,
  onRefreshed,
  addRefreshSubscriber,
  removeTokens,
};
