import { useTheme } from "@mui/material";
import { MenuItem, Select as MuiSelect, SelectProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  backgroundColor: theme.palette.surface.container,
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: "12px",
  width: "100%",

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
  },
}));

const Select = ({
  children,
  options,
  ...props
}: SelectProps & { options?: { value: string; label: string }[] }) => {
  const theme = useTheme();
  return (
    <StyledSelect
      MenuProps={{
        slotProps: {
          paper: {
            sx: {
              backgroundColor: theme.palette.surface.container,
              borderRadius: "12px",
            },
          },
        },
      }}
      {...props}>
      {options
        ? options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Typography variant="body-md">{item.label}</Typography>
            </MenuItem>
          ))
        : children}
    </StyledSelect>
  );
};

export { Select };
