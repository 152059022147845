import { RetroButton } from "components/RetroButton";
import {
  PortfolioDetailListContainer,
  PortfolioDetailListHeader,
  PortfolioDetailListItemCabalIcon,
  PortfolioDetailListItemColumnContainer,
  PortfolioDetailListItemContainer,
  PortfolioDetailListTitle,
  TapsContainer,
  TokenNetworkContainer,
} from "./styled";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { PercentageBrick } from "components/PercentageBrick";
import CoinAvatar from "components/CoinAvatar";
import { mockAssets, mockEarnings, mockGroups } from "./data";

export const PortfolioDetail = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <>
      <TapsContainer>
        {["Assets", "Earning", "Groups"].map((tab, index) => {
          return (
            <RetroButton disabled={selectedTab === index} onClick={() => setSelectedTab(index)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography
                  variant="body-default"
                  color={selectedTab === index ? "text.primary" : "text.brand"}>
                  {tab}
                </Typography>
              </Box>
            </RetroButton>
          );
        })}
      </TapsContainer>
      <PortfolioDetailListContainer>
        {selectedTab === 0 && <AssetsView />}
        {selectedTab === 1 && <EarningView />}
        {selectedTab === 2 && <GroupsView />}
      </PortfolioDetailListContainer>
    </>
  );
};

const AssetsView = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            USD VALUE
          </Typography>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      {mockAssets.map((assetData, index) => (
        <TokensListItem key={index} type="assets" data={assetData} />
      ))}
    </>
  );
};

const EarningView = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            TOKEN
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            USD VALUE
          </Typography>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      {mockEarnings.map((earningData, index) => (
        <TokensListItem key={index} type="earning" data={earningData} />
      ))}
    </>
  );
};

const GroupsView = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PortfolioDetailListHeader>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            CABAL NAME
          </Typography>
        </PortfolioDetailListTitle>
        <PortfolioDetailListTitle>
          <Typography variant="pre-title-2" color="text.secondary">
            USD VALUE
          </Typography>
        </PortfolioDetailListTitle>
      </PortfolioDetailListHeader>
      {mockGroups.map((groupdata, index) => (
        <GroupListItem key={index} data={groupdata} />
      ))}
    </>
  );
};

const TokensListItem = ({ type, data }: { type: "assets" | "earning"; data: any }) => {
  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "32px",
          }}>
          <CoinAvatar iconUri={data.iconUri} chain={data.chain} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "16px",
          }}>
          {/* Balance */}
          <Typography variant="body-default" color="text.primary">
            ${data.balance}
          </Typography>
          {/* Token Type */}
          <TokenNetworkContainer>
            <Typography variant="pre-title-2" color="text.btn-dark">
              {data.tokenType}
            </Typography>
          </TokenNetworkContainer>
          {/* <Typography variant="subtitle" color="text.primary">Cabal Name</Typography> */}
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="body-default" color="text.primary">
            ${data.usdValue}
          </Typography>
          <PercentageBrick percentage={data.percentage} />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          {type === "assets" ? (
            <RetroButton variant="primary" onClick={() => console.log(`To Trade id: ${data.id}`)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography variant="button-md" color="text.brand">
                  TRADE
                </Typography>
              </Box>
            </RetroButton>
          ) : (
            <RetroButton
              variant="primary"
              onClick={() => console.log(`CLAIM id: ${data.id}, balance: ${data.balance}`)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography variant="button-md" color="text.brand">
                  CLAIM
                </Typography>
              </Box>
            </RetroButton>
          )}
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};

const GroupListItem = ({ data }: { data: any }) => {
  return (
    <PortfolioDetailListItemContainer>
      <PortfolioDetailListItemColumnContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "32px",
          }}>
          <PortfolioDetailListItemCabalIcon src={data.iconUri} alt="cabal-image" />
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            padding: "0 12px",
          }}>
          {data.name}
        </Box>
      </PortfolioDetailListItemColumnContainer>
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="body-default" color="text.primary">
            {data.usdValue}
          </Typography>
          <PercentageBrick percentage={data.percentage} />
        </Box>
        <Box
          sx={{
            width: "78px",
            height: "46px",
          }}>
          <RetroButton variant="white">
            <Box
              sx={{
                padding: "12px",
              }}>
              <Typography variant="button-md" color="text.btn-dark">
                VIEW
              </Typography>
            </Box>
          </RetroButton>
        </Box>
      </Box>
    </PortfolioDetailListItemContainer>
  );
};
