import { Box, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { CreateCabalModal } from "./CreateCabalModal";

interface CreateCabalSuccessProps {
  isOpen: boolean;
  close: () => void;
  done: () => void;
}

// TODO: Refactor into styled-components
export const CreateCabalSuccess = ({ isOpen, close, done }: CreateCabalSuccessProps) => {
  return (
    <CreateCabalModal
      isOpen={isOpen}
      close={close}
      type="Success"
      description="Your cabal was created! Now share the news with your community!">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          marginY: "12px",
        }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "363px",
            height: "63px",
          }}>
          <RetroButton variant="primary" onClick={done}>
            <Typography variant="title-h3">DONE</Typography>
          </RetroButton>
        </Box>
      </Box>
    </CreateCabalModal>
  );
};
