import { useRecoilState } from "recoil";
import transactionStoreAtom from "./index";

const localStorageKey = "transactionStore";

const useTransactionStore = () => {
  const [transactionStore, setTransactionStore] = useRecoilState(transactionStoreAtom);

  const setTransaction = (params: { tokenAddress?: string; walletAddress?: string }) => {
    setTransactionStore(params);
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const setTransactionFromLocalStorage = () => {
    const transactionStore = localStorage.getItem(localStorageKey);
    if (transactionStore) {
      setTransactionStore(JSON.parse(transactionStore));
    }
  };

  const resetTransaction = () => {
    setTransactionStore({ tokenAddress: undefined, walletAddress: undefined });
    localStorage.removeItem(localStorageKey);
  };

  return {
    transactionStore,
    setTransactionFromLocalStorage,
    setTransaction,
    resetTransaction,
  };
};

export default useTransactionStore;
