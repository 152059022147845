import { Close } from "@mui/icons-material";
import { Box, Drawer, DrawerProps, styled, Typography, useTheme } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import { RetroButton } from "components/RetroButton";
import { ReactNode } from "react";
import { ReactComponent as XSquare } from "assets/icons/xsquare.svg";
import { abbreviateNumber } from "utils";

export type NumpadKey = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "0" | "." | "delete";

export type TradeDrawerDataProps = {
  tokenAmount: number;
  tonAmount: number;
  usdAmount: number;
  mode: "BUY" | "SELL";
  onClose: () => void;
  onPercentPress: (percent: number) => void;
  onNumpadPress: (key: NumpadKey) => void;
  onConfirm: () => void;
  shouldConfirmDisabled: boolean;
  setMode: (mode: "BUY" | "SELL") => void;
  priceHint?: ReactNode;
  leftButton?: ReactNode;
  confirmButtonConfig?: {
    text: string;
    disabledText: string;
  };
  balanceDisplay: string;
  tokenIcon: string;
};

const StyledDrawer = styled(Drawer)({
  background: "transparent",
  "& .MuiDrawer-paper": {
    background: "transparent",
    display: "flex",
  },
});

const NumpadButton = styled("button")(({ theme }) => ({
  all: "unset",
  width: "33%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "center",

  background: "transparent",
  border: "none",
  cursor: "pointer",
}));

const buttonConfig = [
  {
    label: "10%",
    value: 0.1,
  },
  {
    label: "25%",
    value: 0.25,
  },
  {
    label: "50%",
    value: 0.5,
  },
  {
    label: "MAX",
    value: 1,
  },
];

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  useGrouping: false,
});

export const DarkDrawer = ({ children, ...props }: DrawerProps) => {
  const theme = useTheme();
  return (
    <StyledDrawer {...props}>
      <Box
        sx={{
          background: theme.palette.surface.container,
          border: `1px solid ${theme.palette.border.dark}`,
          borderTopLeftRadius: theme.spacing(3),
          borderTopRightRadius: theme.spacing(3),
          padding: theme.spacing(2.5),
          flex: 1,
        }}>
        {children}
      </Box>
    </StyledDrawer>
  );
};

export const TradeDrawer = (params: DrawerProps & TradeDrawerDataProps) => {
  const theme = useTheme();
  const {
    onClose,
    onPercentPress,
    onNumpadPress,
    onConfirm,
    shouldConfirmDisabled,
    setMode,
    mode,
    tokenAmount,
    tonAmount,
    usdAmount,
    priceHint,
    balanceDisplay,
    tokenIcon,
    leftButton,
    confirmButtonConfig = {
      text: "CONFIRM",
      disabledText: "CONFIRM",
    },
  } = params;

  const closeButton = (
    <CircleButton variant="white" size="lg" onClick={onClose}>
      <Close />
    </CircleButton>
  );
  return (
    <DarkDrawer anchor="bottom" sx={{ zIndex: 100 }} {...params} onClose={onClose}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ visibility: leftButton ? "visible" : "hidden" }}>
          {/* fallback for spacer */}
          {leftButton ?? closeButton}
        </Box>
        <Box
          sx={{
            background: theme.palette.surface.container,
            borderRadius: theme.spacing(2),
            border: `1px solid ${theme.palette.border.dark}`,
            padding: theme.spacing(1),
            display: "flex",
            gap: theme.spacing(1),
          }}>
          <RetroButton onClick={() => setMode("SELL")} disabled={mode === "SELL"}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="body-md">Sell</Typography>
            </Box>
          </RetroButton>
          <RetroButton onClick={() => setMode("BUY")} disabled={mode === "BUY"}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="body-md">Buy</Typography>
            </Box>
          </RetroButton>
        </Box>
        {closeButton}
      </Box>
      <Box
        sx={{
          padding: theme.spacing(1, 2),
          gap: theme.spacing(1),
          display: "flex",
          background: theme.palette.surface.silver,
          borderRadius: theme.spacing(1.5),
          border: `1px solid ${theme.palette.border.dark}`,
          width: "fit-content",
          marginX: "auto",
          marginTop: theme.spacing(2),
          alignItems: "center",
        }}>
        <img src={tokenIcon} alt={balanceDisplay} width={16} height={16} />
        <Typography variant="body-md" color={theme.palette.text.secondary}>
          Balance: {balanceDisplay}
        </Typography>
      </Box>

      <Box sx={{ marginTop: theme.spacing(1), marginX: "auto", textAlign: "center" }}>
        <Box>
          <Typography variant="display-md">{abbreviateNumber(tokenAmount)}</Typography>
        </Box>

        <Typography variant="body-md" color={theme.palette.text.secondary}>
          ≈ {tonAmount.toFixed(2)} TON ({numberFormatter.format(usdAmount)} USD)
        </Typography>
      </Box>
      {priceHint}
      <Box
        display={"flex"}
        gap={theme.spacing(1)}
        marginTop={theme.spacing(1)}
        width={"fit-content"}
        marginX={"auto"}>
        {buttonConfig.map((item) => (
          <RetroButton size="sm" key={item.label} onClick={() => onPercentPress(item.value)}>
            <Box sx={{ padding: theme.spacing(0.0, 2) }}>
              <Typography variant="body-default">{item.label}</Typography>
            </Box>
          </RetroButton>
        ))}
      </Box>

      <Box
        marginTop={theme.spacing(1)}
        display={"flex"}
        flexWrap={"wrap"}
        width={"100%"}
        marginX={"auto"}
        maxWidth={"600px"}>
        {Array.from({ length: 9 }).map((_, index) => (
          <NumpadButton key={index} onClick={() => onNumpadPress(`${index + 1}` as NumpadKey)}>
            <Typography variant="title-h1">{index + 1}</Typography>
          </NumpadButton>
        ))}
        <NumpadButton onClick={() => onNumpadPress(".")}>
          <Typography variant="title-h1">.</Typography>
        </NumpadButton>
        <NumpadButton onClick={() => onNumpadPress("0")}>
          <Typography variant="title-h1">0</Typography>
        </NumpadButton>
        <NumpadButton
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => onNumpadPress("delete")}>
          <XSquare />
        </NumpadButton>
      </Box>

      <RetroButton onClick={onConfirm} disabled={shouldConfirmDisabled}>
        <Box sx={{ padding: theme.spacing(1.5, 3) }}>
          <Typography variant="title-h3">
            {shouldConfirmDisabled ? confirmButtonConfig.disabledText : confirmButtonConfig.text}
          </Typography>
        </Box>
      </RetroButton>
    </DarkDrawer>
  );
};
