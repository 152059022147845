import { Typography } from "@mui/material";

import tradeImage from "../../assets/icons/trade.png";
import { Box } from "@mui/material";
import { RetroCard } from "components/RetroCard";

const LoadingView = () => {
  return (
    <RetroCard>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
        }}>
        <img src={tradeImage} alt="trade" />
        <Box
          sx={{
            textAlign: "center",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "24px",
          }}>
          <Typography variant="title-h2">Loading...</Typography>
        </Box>
      </Box>
    </RetroCard>
  );
};

export { LoadingView };
