import { Box, Typography } from "@mui/material";
import { DepositDetailWrapper, StyledDepositDrawer, WalletAddressContainer } from "./styled";
import { CircleButton } from "components/CircleButton";
import { ReactComponent as TonTokenIcon } from "../../assets/icons/token-ton.svg";
import { ReactComponent as SolTokenIcon } from "../../assets/icons/token-sol.svg";
import { ReactComponent as EthTokenIcon } from "../../assets/icons/token-eth.svg";
import { ReactComponent as BaseTokenIcon } from "../../assets/icons/token-base.svg";
import { RetroButton } from "components/RetroButton";
import { copyToClipboard } from "utils";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { WalletAddresses } from "store/user-store-v2";

export type TokenType = "TON" | "SOL" | "ETH" | "BASE";

interface TokenConfig {
  symbol: string;
  networkName: string;
  walletName: keyof WalletAddresses;
  icon: JSX.Element;
}

const configs: { [key in TokenType]: TokenConfig } = {
  TON: {
    symbol: "TON",
    networkName: "TON COIN",
    walletName: "tonAddress",
    icon: <TonTokenIcon />,
  },
  SOL: {
    symbol: "SOL",
    networkName: "SOLANA",
    walletName: "solAddress",
    icon: <SolTokenIcon />,
  },
  ETH: {
    symbol: "ETH",
    networkName: "ETHEREUM",
    walletName: "ethAddress",
    icon: <EthTokenIcon />,
  },
  BASE: {
    symbol: "BASE",
    networkName: "BASE",
    walletName: "ethAddress", // Pending API update to support Base wallet (turnkey)
    icon: <BaseTokenIcon />,
  },
};

export const DepositModal = ({
  tokenType,
  isOpen,
  close,
}: {
  tokenType: TokenType;
  isOpen: boolean;
  close: () => void;
}) => {
  const { walletAddresses } = useUserStoreV2();
  const config = configs[tokenType];

  const handleCopy = () => {
    if (!walletAddresses) return;
    const walletAddress = walletAddresses[config.walletName as keyof WalletAddresses];
    if (walletAddress !== undefined) {
      copyToClipboard(walletAddress);
    }
  };

  return (
    <StyledDepositDrawer anchor={"bottom"} open={isOpen} onClose={close}>
      <Box
        sx={{
          marginLeft: "auto",
        }}>
        <CircleButton variant="white" onClick={close}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            X
          </Box>
        </CircleButton>
      </Box>
      <DepositDetailWrapper>
        <Box
          sx={{
            marginBottom: "12px",
          }}>
          {configs[tokenType].icon}
        </Box>
        <Box
          sx={{
            marginBottom: "10px",
          }}>
          <Typography variant="display-md">DEPOSIT</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "343px",
            textAlign: "center",
            marginBottom: "48px",
          }}>
          <Typography variant="body-lg" color="text.secondary">
            Send only {tokenType} and tokens in {config.networkName} network to this address, or you
            might lose your funds.
          </Typography>
        </Box>
        <WalletAddressContainer>
          <Typography variant="body-md" color="text.primary">
            {walletAddresses ? walletAddresses[config.walletName as keyof WalletAddresses] : ""}
          </Typography>
        </WalletAddressContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "300px",
            marginX: "auto",
          }}>
          <RetroButton variant="primary" onClick={handleCopy}>
            <Typography variant="title-h3" sx={{ padding: "12px 21.5px" }}>
              COPY ADDRESS
            </Typography>
          </RetroButton>
        </Box>
      </DepositDetailWrapper>
    </StyledDepositDrawer>
  );
};
