import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const Overlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
}));

export const ModalWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "411px",
  padding: "20px",
}));

export const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  width: "52px",
  height: "52px",
  borderRadius: "50%",
}));

export const CheckCircleImage = styled("img")(({ theme }) => ({
  width: "101px",
  height: "101px",
  margin: "0 auto",
}));
