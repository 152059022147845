import { RetroCard } from "components/RetroCard";
import {
  ModalWrapper,
  Overlay,
  PasswordButtonGroups,
  PasswordInput,
  ViewPasswordButton,
} from "./styled";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import { ReactComponent as PrivateIcon } from "../../assets/icons/private.svg";
import { ReactComponent as ShowIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as HideIcon } from "../../assets/icons/eye-slash.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-brand.svg";
import { useRef, useState } from "react";
import { RetroButton } from "components/RetroButton";
import { copyToClipboard } from "utils";

interface ExportPrivateKeyModalProps {
  isOpen: boolean;
  close: () => void;
}

// TODO: Refactor into styled-components
export const ExportPrivateKeyModal = ({ isOpen, close }: ExportPrivateKeyModalProps) => {
  return isOpen ? (
    <Overlay>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen}
        onClose={close}>
        <ModalWrapper>
          <RetroCard>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: "20px",
                gap: "16px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}>
                <CircleButton variant="white" onClick={close}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    X
                  </Box>
                </CircleButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "16px",
                }}>
                <PrivateIcon width={84} height={87} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginX: "auto",
                  textAlign: "center",
                  width: "300px",
                  gap: "16px",
                  marginBottom: "20px",
                }}>
                <Typography variant="title-h1">YOUR PRIVATE KEY</Typography>
                <Typography variant="body-lg" color="text.secondary">
                  Never give your private key to anyone
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "start",
                  }}>
                  <Typography variant="title-h3" color="text.secondary">
                    TON
                  </Typography>
                  <PasswordInputs
                    password={
                      "moSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhM"
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "start",
                  }}>
                  <Typography variant="title-h3" color="text.secondary">
                    SOL
                  </Typography>
                  <PasswordInputs
                    password={
                      "moSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhM"
                    }
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "start",
                  }}>
                  <Typography variant="title-h3" color="text.secondary">
                    ETH
                  </Typography>
                  <PasswordInputs
                    password={
                      "moSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhMmoSPhAy1PVg8hhhM"
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "300px",
                  marginX: "auto",
                }}>
                <RetroButton variant="white" onClick={close}>
                  <Typography variant="title-h3" sx={{ padding: "12px 24px" }}>
                    DONE
                  </Typography>
                </RetroButton>
              </Box>
            </Box>
          </RetroCard>
        </ModalWrapper>
      </Modal>
    </Overlay>
  ) : (
    <></>
  );
};

const PasswordInputs = ({ password }: { password: string }) => {
  const [isShowPassword, setShowPassword] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleCopyWalletAddress = () => {
    copyToClipboard(password);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  const handleShowPassword = () => {
    setShowPassword(!isShowPassword);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
        }}>
        <PasswordInput
          type={isShowPassword ? "text" : "password"}
          ref={passwordRef}
          disabled
          value={password}
        />
        <PasswordButtonGroups>
          <ViewPasswordButton
            $variant={isShowPassword ? "text" : "password"}
            onClick={handleShowPassword}>
            {isShowPassword ? (
              <ShowIcon width={16} height={16} />
            ) : (
              <HideIcon width={16} height={16} />
            )}
          </ViewPasswordButton>
          {isCopied ? (
            <Typography color="text.system-green">✔</Typography>
          ) : (
            <IconButton sx={{ padding: "0px" }} onClick={handleCopyWalletAddress}>
              <CopyIcon width={16} height={16} />
            </IconButton>
          )}
        </PasswordButtonGroups>
      </Box>
    </>
  );
};
