import { Outlet, useNavigate } from "react-router-dom";
import CabalTownLogo from "../../assets/icons/cabal-town-logo.png";
import { HeaderContainer, HeaderLogo } from "./styled";
import { HeaderProfile } from "./headerProfile";
import { RetroButton } from "components/RetroButton";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Container } from "components/container";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { ROUTES } from "consts";
import useToastStore from "store/toast-store/useToastStore";
import { HeaderToast } from "./headerToast";
import { Brick } from "components/Brick";

const LOGIN_REDIRECT_URL = process.env.REACT_APP_TG_WIDGET_LOGIN_URL;

export const Header = () => {
  const { tgUserId, tgUserName, walletAddresses, tgPhotoUrl, getTgUserIdV2 } = useUserStoreV2();
  const { isShowHeader } = useLayoutConfigStore();
  const { tonAddress } = walletAddresses || {};
  const theme = useTheme();

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      // Remove replace with Turnkey
      if (!tgUserId || !tgUserName) {
        getTgUserIdV2();
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  const handleLogout = () => {
    navigate(ROUTES.portfolio);
  };

  const { toastState } = useToastStore();

  return (
    <>
      <Box>
        <Box sx={{ background: theme.palette.surface.container }}>
          <Container>
            {isShowHeader ? (
              <HeaderContainer>
                <Brick variant="darker">
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    padding={theme.spacing(0.3)}>
                    <HeaderLogo src={CabalTownLogo} alt="cabal-town-logo" />
                  </Box>
                </Brick>
                {tonAddress && tgUserName ? (
                  <HeaderProfile name={tgUserName} avatar={tgPhotoUrl} onClick={handleLogout} />
                ) : (
                  <Box sx={{ width: "120px", alignSelf: "stretch" }}>
                    <RetroButton variant="white" onClick={handleLogin}>
                      <Typography variant="title-h3">Signup</Typography>
                    </RetroButton>
                  </Box>
                )}
              </HeaderContainer>
            ) : null}
          </Container>
        </Box>

        <HeaderToast
          isOpen={!!toastState.message}
          variant={toastState.variant}
          message={toastState.message}
        />
      </Box>

      <Box sx={{ marginTop: theme.spacing(1) }}></Box>
      {/* spacer */}

      <Outlet />
    </>
  );
};
