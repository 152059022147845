import { Box, styled } from "@mui/material";

const RETRO_CARD_RADIUS = {
  md: "24px",
  sm: "12px",
};

const RetroCardContainer = styled(Box)<{ size: "md" | "sm"; narrowOuter: boolean }>(
  ({ theme, size, narrowOuter }) => ({
    backgroundColor: theme.palette.surface["container-bg"],
    borderRadius: RETRO_CARD_RADIUS[size],
    border: `1px solid ${theme.palette.border.dark}`,
    padding: narrowOuter ? "0 2px 10px" : "0 4px 10px",
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "auto",
    "&:after": {
      content: '""',
      position: "absolute",
      left: RETRO_CARD_RADIUS[size],
      right: RETRO_CARD_RADIUS[size],
      height: "20px",
      bottom: 0,
      borderLeft: `1px solid ${theme.palette.border.dark}`,
      borderRight: `1px solid ${theme.palette.border.dark}`,
    },
  }),
);

const RetroCardContent = styled(Box)<{ size: "md" | "sm" }>(({ theme, size }) => ({
  borderRadius: RETRO_CARD_RADIUS[size],
  border: `1px solid ${theme.palette.border.dark}`,
  backgroundColor: theme.palette.surface["container"],
  marginTop: "-1px",
  position: "relative",
  height: "100%",
  overflow: "auto",
  zIndex: 1,
}));

export { RetroCardContainer, RetroCardContent };
