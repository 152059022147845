import { Box, DrawerProps, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { useEffect, useState } from "react";
import { DarkDrawer, TradeDrawer } from "components/TradeDrawer";
import RetroModal from "components/RetroModal";
import { useBuyLogic, useSellLogic } from "./logic";
import { MIN_ENTRY_AMOUNT } from "consts";
import { abbreviateNumber } from "utils";
import TonIcon from "assets/icons/chains/ton.png";
import useTransactionStore from "store/transaction-store/useTransationStore";

export const CabalTradeDrawer = (
  params: DrawerProps & { onClose: () => void } & {
    tokenQuantity: number;
    jettonIcon: string;
    balance: number;
    masterAddress: string;
    buyMinimum: number;
    tgGroupId: number;
    onConfirm?: (message?: string) => void;
    onFail?: (message?: string) => void;
  },
) => {
  const theme = useTheme();
  const [mode, setMode] = useState<"BUY" | "SELL">("BUY");

  const [modalConfig, setModalConfig] = useState<{
    variant: "success" | "error";
    title: string;
    message: string;
  } | null>(null);

  const [shouldShowAmountTooSmall, setShouldShowAmountTooSmall] = useState(false);
  const [shouldShowConfirmToLeave, setShouldShowConfirmToLeave] = useState(false);
  const [shouldShowConfirmDetails, setShouldShowConfirmDetails] = useState(false);

  const buyLogic = useBuyLogic({ ...params, setModalConfig });
  const sellLogic = useSellLogic({ ...params, setModalConfig });

  const { transactionStore } = useTransactionStore();

  const {
    usdAmount,
    tokenAmount,
    tonAmount,
    shouldConfirmDisabled,
    isProcessing,
    priceHint,
    onConfirm,
    onNumpadPress,
    onClose,
    onPercentPress,
    reset,
  } = mode === "BUY" ? buyLogic : sellLogic;

  const handleConfirm = () => {
    // extra logic for outer component
    if (mode === "BUY" && +usdAmount < params.buyMinimum) {
      setShouldShowAmountTooSmall(true);
      return;
    }

    if (mode === "SELL" && params.tokenQuantity - tokenAmount < MIN_ENTRY_AMOUNT) {
      setShouldShowConfirmToLeave(true);
      return;
    }

    setShouldShowConfirmDetails(true);
  };

  const cancelConfirmDetails = () => {
    setShouldShowConfirmDetails(false);
  };

  const handleConfirmDetails = () => {
    setShouldShowConfirmDetails(false);
    onConfirm?.();
  };

  const cancelConfirmToLeave = () => {
    setShouldShowConfirmToLeave(false);
  };

  const handleConfirmToLeave = () => {
    setShouldShowConfirmToLeave(false);
    onConfirm?.();
  };

  useEffect(() => {
    reset();
  }, [reset, mode]);

  return (
    <>
      <TradeDrawer
        {...params}
        mode={mode}
        shouldConfirmDisabled={
          shouldConfirmDisabled ||
          !!transactionStore.tokenAddress ||
          !!transactionStore.walletAddress
        }
        setMode={setMode}
        onConfirm={handleConfirm}
        onNumpadPress={onNumpadPress}
        onClose={() => {
          reset();
          setMode("BUY");
          onClose();
        }}
        balanceDisplay={
          mode === "BUY"
            ? params.balance.toFixed(2)
            : abbreviateNumber(params.tokenQuantity).toString()
        }
        tokenIcon={mode === "BUY" ? TonIcon : params.jettonIcon}
        tokenAmount={tokenAmount}
        tonAmount={tonAmount}
        usdAmount={usdAmount}
        onPercentPress={onPercentPress}
        priceHint={priceHint}
        confirmButtonConfig={{
          text: "CONFIRM",
          disabledText: isProcessing ? "Processing..." : "CONFIRM",
        }}
      />

      <DarkDrawer
        open={shouldShowAmountTooSmall}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Amount is too small</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            The minimum amount per deposit is ${params.buyMinimum}
          </Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <RetroButton onClick={() => setShouldShowAmountTooSmall(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              OK
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>
      <DarkDrawer
        open={shouldShowConfirmDetails}
        anchor="bottom"
        onClose={cancelConfirmDetails}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Confirm Details</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            {mode === "BUY" ? "Buy" : "Sell"} {tokenAmount} Jetton for {tonAmount.toFixed(2)} TON
            (~${usdAmount.toFixed(2)} USD)
          </Typography>
        </Box>

        <Box marginTop={theme.spacing(1)} display="flex" gap={theme.spacing(1)}>
          <RetroButton onClick={cancelConfirmDetails} variant="white">
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              Go Back
            </Typography>
          </RetroButton>
          <RetroButton onClick={handleConfirmDetails}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              Continue
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>
      <DarkDrawer
        open={shouldShowConfirmToLeave}
        anchor="bottom"
        onClose={cancelConfirmToLeave}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Are you sure to leave?</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            If you keep less than {abbreviateNumber(MIN_ENTRY_AMOUNT)} balance in your wallet you
            will be removed from the group.
          </Typography>
        </Box>

        <Box marginTop={theme.spacing(1)} display="flex" gap={theme.spacing(1)}>
          <RetroButton onClick={cancelConfirmToLeave}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              Go Back
            </Typography>
          </RetroButton>
          <RetroButton onClick={handleConfirmToLeave} variant="white">
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              Continue
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>
      <RetroModal
        open={modalConfig !== null}
        onClose={() => setModalConfig(null)}
        variant={modalConfig?.variant ?? "success"}
        title={modalConfig?.title ?? "Default Title"}
        message={modalConfig?.message ?? "Default Message"}
      />
    </>
  );
};
