import { Box, Typography, useTheme, styled } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { Select } from "components/Select";
import { useEffect, useState } from "react";
import Pagination from "components/Pagination";
import { Container } from "components/container";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { LoadingView } from "components/LoadingView";
import { EmptyView } from "components/EmptyView";
import { SearchField } from "components/SearchField";
import { CabalGroupHeader, CabalGroupRow } from "components/CabalGroup";
import { useDebounce } from "react-use";
import { ScrollableBox } from "components/ScrollableBox";
import { Jetton } from "types";
import { LoadMoreRow } from "components/LoadMoreRow";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";

const ExplorerPage = () => {
  const theme = useTheme();
  const languageSelectOptions = [
    { label: "All", value: "all" },
    {
      label: "English",
      value: "en",
    },
    {
      label: "한국어",
      value: "ko",
    },
    {
      label: "中文",
      value: "zh_hk",
    },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState("all");
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);

  const [debouncedName, setDebouncedName] = useState("");

  const [, cancel] = useDebounce(
    () => {
      setDebouncedName(name);
    },
    500,
    [name],
  );

  // const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   setPage(value);
  // };

  const Renderer = () => {
    // const { data, isLoading } = useQuery({
    //   queryKey: [
    //     "cabal-calls",
    //     "cabalsExploration",
    //     { page, language: selectedLanguage, name: debouncedName },
    //   ],
    //   queryFn: async () => {
    //     return axiosService.getJettons({
    //       field: "cabalsExploration",
    //       filter: {
    //         name: debouncedName || undefined, // for empty string, remove the filter
    //         language: selectedLanguage,
    //       },
    //       selects: ["name", "tgGroupId", "imageUri", "language", "masterAddress", "createdAt"],
    //       limit: 10,
    //       page,
    //     });
    //   },
    // });

    const queryResult = useInfiniteQuery({
      queryKey: [
        "cabal-calls",
        "cabalsExploration",
        { page, language: selectedLanguage, name: debouncedName },
      ],
      queryFn: async ({ pageParam }) => {
        return axiosService.getJettons({
          field: "cabalsExploration",
          filter: {
            language: selectedLanguage,
          },
          selects: ["name", "tgGroupId", "imageUri", "language", "masterAddress", "createdAt"],
          limit: 10,
          name: debouncedName || undefined, // for empty string, remove the filter
          page: pageParam,
        });
      },
      getNextPageParam: (lastPage, pages) => {
        return lastPage.page + 1;
      },

      getPreviousPageParam: (firstPage, pages) => {
        return firstPage.page - 1;
      },
      initialPageParam: 1,
    });

    const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

    const { data } = queryResult;

    if (!data || data.pages[0].totalResults === 0) {
      return <EmptyView />;
    }

    return (
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2.5),
            gap: theme.spacing(1),
            height: "100%",
          }}>
          <Typography variant="title-h3" color={theme.palette.text.primary}>
            Explore Cabals
          </Typography>
          <CabalGroupHeader />
          <ScrollableBox>
            {data.pages
              .flatMap((page) => page.results)
              .map((jetton: Jetton) => (
                <Box
                  sx={{
                    marginTop: theme.spacing(0.5),
                  }}
                  key={jetton._id}>
                  <CabalGroupRow jetton={jetton} />
                </Box>
              ))}
            {!hasAllFetched && <LoadMoreRow ref={ref} />}
          </ScrollableBox>
          {/* <Container sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Pagination
              count={data.totalPages}
              page={page}
              onChange={handlePageChange}
              siblingCount={0}
            />
          </Container> */}
        </Box>
      </RetroCard>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1), height: "100%" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <SearchField
          sx={{ flex: 1 }}
          onChange={(event) => setName(event.target.value)}
          value={name}
        />
        <Select
          value={selectedLanguage}
          options={languageSelectOptions}
          onChange={(event) => setSelectedLanguage(event.target.value as string)}
          sx={{ width: theme.spacing(15) }}
        />
      </Box>
      <Renderer />
    </Box>
  );
};

export { ExplorerPage };
