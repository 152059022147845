import { Box } from "@mui/material";

import { styled } from "@mui/material";
import { APP_GRID } from "consts";

const Container = styled(Box)(({ theme }) => ({
  maxWidth: APP_GRID,
  width: "100%",
  margin: "0 auto",
  padding: theme.spacing(0, 1.25),

  [`@media (min-width: ${APP_GRID}px)`]: {
    padding: "0",
  },
}));

export { Container };
