import { Box, Typography, useTheme } from "@mui/material";

const PriceHint = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginTop: theme.spacing(1),
        marginX: "auto",
        textAlign: "center",
        width: "fit-content",
        background: theme.palette.system.red,
        padding: theme.spacing(1, 2),
        borderRadius: theme.spacing(1.5),
        border: `1px solid ${theme.palette.border.dark}`,
      }}>
      <Typography variant="body-default" color={theme.palette.text["system-red"]}>
        insufficient balance for trading
      </Typography>
    </Box>
  );
};

export default PriceHint;
