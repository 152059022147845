import { CreateCabalModal } from "./CreateCabalModal";

interface CreateCabalErrorProps {
  isOpen: boolean;
  close: () => void;
  errorMessage: string;
}

export const CreateCabalError = ({ isOpen, close, errorMessage }: CreateCabalErrorProps) => {
  return <CreateCabalModal isOpen={isOpen} close={close} type="Error" description={errorMessage} />;
};
