import axios from "axios";
import { ILoginUser, IInsertUser, IJoinGroup, IUpdatePurge } from "store/user-store";
import { IInsertJetton } from "store/jetton-list-store";
import axiosInstance from "./axiosInstance";
import qs from "qs";
import { getAccessToken } from "./authV2";
import {
  BuyTonPreviewResponse,
  CabalDetails,
  CoinPriceRatio,
  Jetton,
  JettonBalance,
  JettonPrice,
  JettonPriceRatio,
  PaginatedData,
  SellTonPreviewResponse,
  Token,
  TokenCall,
  TokenHolder,
  TokenPrice,
} from "types";
const { REACT_APP_API_URL, REACT_APP_TON_CLIENT_API_URL } = process.env;

const isTestnet = process.env.REACT_APP_TESTNET === "1";

async function redeemCode(code: string) {
  return {
    res: (await axiosInstance.post(REACT_APP_API_URL + "/v1/utils/code_redeem", { code })).data,
  };
}

async function verifyToken(refreshToken: string) {
  return {
    res: (await axiosInstance.post(REACT_APP_API_URL + "/v1/auth/verify-tokens", { refreshToken }))
      .data,
  };
}

async function insertJetton(data: IInsertJetton) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/insert";
  const res = await axiosInstance.post(endpoint, data);

  return {
    res: res.data,
    status: res.status,
  };
}

// V2
async function insertJettonV2(data: IInsertJetton) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/insert";
  const accessToken = getAccessToken();
  const res = await axiosInstance.post(endpoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return {
    res: res.data,
    status: res.status,
  };
}

async function getJettonList(chain: string, field?: string) {
  let endpoint = `${REACT_APP_API_URL}/v1/jettons?limit=100&sortBy=createdAt:desc&chain=${chain}`;
  if (field) endpoint += `&field=${field}`;
  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJetton(masterAddress: string) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/" + masterAddress;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonPrice(masterAddress: string, limit: number) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/price?limit=${limit}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonHolders(masterAddress: string, chain: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/holders?chain=${chain}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonTxns(masterAddress: string, chain: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/txns?chain=${chain}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonUpdates(
  masterAddress: string,
  jettonId: string,
  lt: number,
  chain: string,
) {
  const endpoint =
    REACT_APP_API_URL + `/v1/jettons/${masterAddress}/${jettonId}?lt=${lt}&chain=${chain}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonsByOwner(ownerAddress: string, network: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${ownerAddress}/profile/list?chain=${network}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getTonPrice() {
  const endpoint = REACT_APP_TON_CLIENT_API_URL + "/v2/rates?tokens=ton&currencies=usd";

  return (await axiosInstance.get(endpoint)).data;
}

async function loginUser(data: ILoginUser) {
  const endpoint = REACT_APP_API_URL + "/v1/auth/login";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function createUser(data: IInsertUser) {
  const endpoint = REACT_APP_API_URL + "/v1/auth/register";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function joinGroup(data: IJoinGroup) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/joinGroup";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function updateJettonPurge(data: IUpdatePurge) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/updatePurge";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function uploadFile(file: File): Promise<string | undefined> {
  const endpoint = REACT_APP_API_URL + "/v1/utils/upload";

  const formData = new FormData();
  formData.append("file", file);

  try {
    return (
      await axiosInstance.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data.url;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

async function getGroupChatMsgs(groupId: string) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/groupChat?tgGroupId=" + groupId;

  try {
    return (await axiosInstance.get(endpoint)).data;
  } catch (error) {
    console.error("Error getGroupChatMsgs:", error);
  }
}

async function getTokenBalance({
  tokenAddress,
  walletAddress,
  chain,
}: {
  tokenAddress: string;
  walletAddress: string;
  chain: string;
}) {
  try {
    const endpoint =
      REACT_APP_API_URL +
      `/v1/cabal/getCabalCalls/${tokenAddress}/balance?walletAddress=${walletAddress}&chain=${chain}`;
    const accessToken = getAccessToken();

    return (
      await axiosInstance.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data;
  } catch (error) {
    console.error("Error getTokenBalance:", error);
  }
}

async function tokenTradePreview({
  priceUsd,
  tokenAddress,
  chain,
}: {
  priceUsd: number;
  tokenAddress: string;
  chain: string;
}) {
  try {
    const endpoint = REACT_APP_API_URL + `/v1/trade/tokenSwapPreview`;
    const accessToken = getAccessToken();

    return (
      await axiosInstance.post(
        endpoint,
        {
          priceUsd,
          chain,
          tokenAddress,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
    ).data;
  } catch (error) {
    console.error("Error getTokenBalance:", error);
  }
}

async function getNativePrice({ tokenAddress, chain }: { tokenAddress: string; chain: string }) {
  try {
    const endpoint =
      REACT_APP_API_URL + `/v1/trade/nativePrice?tokenAddress=${tokenAddress}&chain=${chain}`;
    const accessToken = getAccessToken();

    return (
      await axiosInstance.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data;
  } catch (error) {
    console.error("Error getTokenBalance:", error);
  }
}

async function tokenTrade({
  srcToken,
  destToken,
  senderAddress,
  nativeSpent,
  priceUsd,
  slippage,
  chain,
  tradeType,
  tgGroupId,
}: {
  srcToken: string;
  destToken: string;
  senderAddress: string;
  nativeSpent: number;
  priceUsd: number;
  slippage: number;
  chain: string;
  tradeType: string;
  tgGroupId: number;
}) {
  try {
    const endpoint = REACT_APP_API_URL + `/v1/trade/tokenSwap`;
    const accessToken = getAccessToken();

    return (
      await axiosInstance.post(
        endpoint,
        {
          srcToken,
          destToken,
          senderAddress,
          nativeSpent,
          priceUsd,
          slippage,
          chain,
          tradeType,
          tgGroupId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
    ).data;
  } catch (error) {
    console.error("Error getTokenBalance:", error);
  }
}

async function getCabalCalls({
  limit = 10,
  page = 1,
  sortBy = "createdAt:desc",
  selects,
  field,
  filter,
  selectedDay,
}: {
  sortBy?: string;
  limit?: number;
  page?: number;
  selects?: string[];
  field?: string;
  filter?: Record<string, string | undefined>;
  selectedDay?: "day7Result" | "day14Result" | "day1Result";
}) {
  const queryString = qs.stringify({
    selectedDay,
    field,
    filter: Object.entries(filter ?? {})
      .filter(([k, v]) => v !== undefined)
      .filter(([k, v]) => k !== "language" || v !== "all")
      .map(([k, v]) => `${k}:${v}`)
      .join(","),
    limit,
    page,
    sortBy,
    selects: selects?.join(" "),
  });
  const endpoint = REACT_APP_API_URL + `/v1/cabal/getCabalCalls?${queryString}`;
  return (await axiosInstance.get<PaginatedData<TokenCall>>(endpoint)).data;
}

async function getCabalDetails(id: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/groupId/${id}`;
  return (await axiosInstance.get<CabalDetails>(endpoint)).data;
}

async function getJettons({
  limit = 10,
  page = 1,
  sortBy = "createdAt:desc",
  filter,
  field,
  selects,
  selectedDay = "all",
  name,
}: {
  limit?: number;
  page?: number;
  sortBy?: string;
  field?: string;
  filter?: Record<string, string | undefined>;
  selects?: string[];
  selectedDay?: "day7Result" | "day14Result" | "day1Result" | "all";
  name?: string;
}) {
  const queryString = qs.stringify({
    selectedDay,
    name,
    field,
    selects: selects?.join(" "),
    limit,
    page,
    sortBy,
    filter: Object.entries(filter ?? {})
      .filter(([k, v]) => v !== undefined)
      .filter(([k, v]) => k !== "language" || v !== "all")
      .map(([k, v]) => `${k}:${v}`)
      .join(","),
  });
  const endpoint = REACT_APP_API_URL + `/v1/jettons?${queryString}`;
  return (await axiosInstance.get<PaginatedData<Jetton>>(endpoint)).data;
}

async function getJettonPriceV1({
  masterAddress,
  interval = "minute",
  sortBy = "createdAt:desc",
  page = 1,
  limit = 10000,
  selects = ["price", "updatedAt"],
}: {
  masterAddress: string;
  interval: "minute" | "hour" | "day" | "week";
  sortBy?: string;
  page?: number;
  limit?: number;
  selects?: string[];
}) {
  const queryString = qs.stringify({
    interval,
    sortBy,
    page,
    limit,
    selects: selects.join(" "),
  });

  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/price?${queryString}`;
  return (await axiosInstance.get<PaginatedData<JettonPrice>>(endpoint)).data;
}

async function getJettonBalance({
  masterAddress,
  walletAddress,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  walletAddress: string;
  chain?: string;
}) {
  const endpoint =
    REACT_APP_API_URL +
    `/v1/jettons/${masterAddress}/balance?chain=${chain}&walletAddress=${walletAddress}`;
  return (await axiosInstance.get<JettonBalance>(endpoint)).data;
}

async function buyTonPreview({
  masterAddress,
  priceUsd,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  priceUsd: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/buyTonPreview`;
  return (
    await axiosInstance.post<BuyTonPreviewResponse>(endpoint, {
      masterAddress,
      priceUsd,
      chain,
    })
  ).data;
}

async function sellTonPreview({
  masterAddress,
  sellAmount,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  sellAmount: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/sellTonPreview`;
  return (
    await axiosInstance.post<SellTonPreviewResponse>(endpoint, {
      masterAddress,
      sellAmount,
      chain,
    })
  ).data;
}

async function buyTonJetton({
  masterAddress,
  tonAmount,
  tgGroupId,
  chain = process.env.REACT_APP_TESTNET ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  tonAmount: number;
  tgGroupId: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/buyTonJetton`;
  return (
    await axiosInstance.post<{ status: number; message: string }>(endpoint, {
      masterAddress,
      tonAmt: tonAmount,
      chain,
      tgGroupId,
    })
  ).data;
}

async function sellTonJetton({
  masterAddress,
  jettonAmt,
  tgGroupId,
  chain = process.env.REACT_APP_TESTNET ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  jettonAmt: number;
  tgGroupId: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/sellTonJetton`;
  return (
    await axiosInstance.post<{ status: number; message: string }>(endpoint, {
      masterAddress,
      jettonAmt,
      tgGroupId,
      chain,
    })
  ).data;
}

async function getToken({ tokenAddress }: { tokenAddress: string }) {
  const endpoint = REACT_APP_API_URL + `/v1/cabal/getCabalCalls/${tokenAddress}`;
  return (await axiosInstance.get<Token>(endpoint)).data;
}

async function getTokenHolders({ tokenAddress, chain }: { tokenAddress: string; chain: string }) {
  const endpoint =
    REACT_APP_API_URL + `/v1/cabal/getCabalCalls/${tokenAddress}/holders?chain=${chain}`;
  return (await axiosInstance.get<TokenHolder[]>(endpoint)).data;
}

async function getCabalCallsPrice({
  pairAddress,
  chain,
  interval = "minute",
}: {
  pairAddress: string;
  chain: string;
  interval: "minute" | "hour" | "day" | "week";
}) {
  const queryString = qs.stringify({
    interval,
  });

  const endpoint =
    REACT_APP_API_URL +
    `/v1/cabal/getCabalCalls/${pairAddress}/price?chain=${chain}&${queryString}`;
  return (await axiosInstance.get<TokenPrice[]>(endpoint)).data;
}

async function traceEvent({
  masterAddress,
  walletAddress,
}: {
  masterAddress: string;
  walletAddress: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/traceEvent`;
  const queryString = qs.stringify({
    masterAddress,
    walletAddress,
  });
  return (
    await axiosInstance.get<{ status: number; message: string }>(`${endpoint}?${queryString}`)
  ).data;
}

async function joinGroupV1(data: {
  tokenAddress: string;
  walletAddress: string;
  tgUserName: string;
}) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/joinGroup";

  return (await axiosInstance.post<{ status: number; message: string }>(endpoint, data)).data;
}

const axiosService = {
  getNativePrice,
  getJettonUpdates,
  getJettonPrice,
  getJettonList,
  getTonPrice,
  getJetton,
  insertJetton,
  insertJettonV2,
  getJettonsByOwner,
  getJettonHolders,
  getJettonTxns,
  loginUser,
  createUser,
  joinGroup,
  updateJettonPurge,
  redeemCode,
  verifyToken,
  uploadFile,
  getGroupChatMsgs,
  getCabalCalls,
  getCabalDetails,
  getJettons,
  getJettonPriceV1,
  getJettonBalance,
  buyTonPreview,
  buyTonJetton,
  sellTonPreview,
  sellTonJetton,
  getTokenHolders,
  getToken,
  getCabalCallsPrice,
  getTokenBalance,
  tokenTradePreview,
  tokenTrade,
  traceEvent,
  joinGroupV1,
};

export default axiosService;
