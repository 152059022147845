import { Box, Button, styled } from "@mui/material";

const ProfileContainer = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "16px",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.surface.silver,
  padding: "0 16px",
  height: "53px",
  gap: "8px",

  [theme.breakpoints.down("sm")]: {
    padding: "0 8px",
  },
}));

const ProfileAvatar = styled("img")(({ theme }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "16px",
  border: `1px solid  ${theme.palette.border.dark}`,
}));

export { ProfileContainer, ProfileAvatar };
