import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { NumpadKey, TradeDrawerDataProps } from "components/TradeDrawer";
import { useCallback, useEffect, useState } from "react";
import axiosService from "services/axios";
import PriceHint from "./priceHint";
import { MIN_ENTRY_AMOUNT } from "consts";

type TradeLogic = Omit<
  TradeDrawerDataProps,
  "mode" | "setMode" | "tonBalance" | "balanceDisplay" | "tokenIcon"
> & {
  reset: () => void;
  isProcessing: boolean;
};

const AMOUNT_STEP = 1000;
const INITIAL_AMOUNT_STRING = (MIN_ENTRY_AMOUNT / AMOUNT_STEP).toString();

const PREVIEW_REFETCH_INTERVAL = 1000 * 10;

export const useBuyLogic = ({
  masterAddress,
  balance,
  tgGroupId,
  onConfirm,
  onFail,
  onClose,
  setModalConfig,
}: {
  buyMinimum: number;
  masterAddress: string;
  balance: number;
  tgGroupId: number;
  onConfirm?: (message?: string) => void;
  onFail?: (message?: string) => void;
  onClose?: () => void;
  setModalConfig?: (config: {
    variant: "success" | "error";
    title: string;
    message: string;
  }) => void;
}): TradeLogic => {
  const [amountString, setAmountString] = useState(INITIAL_AMOUNT_STRING);
  const [tempUsdAmount, setTempUsdAmount] = useState(0);
  const [tempTonAmount, setTempTonAmount] = useState(0);
  const [isBuying, setIsBuying] = useState(false);

  const handleOnClose = () => {
    setAmountString(INITIAL_AMOUNT_STRING);
    onClose?.();
  };

  const { data: jettonPriceRatio } = useQuery({
    queryKey: ["jetton-price", masterAddress],
    queryFn: () => axiosService.getNativePrice({ tokenAddress: masterAddress, chain: "ton" }),
    enabled: !!masterAddress,
    refetchInterval: 10 * 1000,
  });

  useEffect(() => {
    if (jettonPriceRatio && "jettonBuyRatio" in jettonPriceRatio) {
      const usdValue = jettonPriceRatio.jettonBuyRatio * +amountString * AMOUNT_STEP;
      const tonValue = usdValue / jettonPriceRatio.nativePrice;
      setTempUsdAmount(usdValue);
      setTempTonAmount(tonValue);
    }
  }, [jettonPriceRatio, amountString]);

  const handleConfirmClick = async () => {
    try {
      // TODO: confirm
      setIsBuying(true);
      const res = await buyTonJetton({ tonAmount: tempTonAmount, masterAddress, tgGroupId });
      if (res.status === 200) {
        setModalConfig?.({
          variant: "success",
          title: "Success",
          message: res.message,
        });
        reset();
        onConfirm?.(res.message);
      } else {
        setModalConfig?.({
          variant: "error",
          title: "Error",
          message: res.message,
        });
        onFail?.(res.message);
      }
    } catch (error) {
      console.error(error);
      onFail?.("Something went wrong. Please try again.");
    } finally {
      setIsBuying(false);
    }
  };

  const handleNumpadPress = (key: NumpadKey) => {
    if (key === "delete") {
      if (amountString.length > 1) {
        setAmountString(amountString.slice(0, -1));
      } else {
        setAmountString("0");
      }
    } else if (key === ".") {
      if (!amountString.includes(".")) {
        setAmountString(amountString + key);
      }
    } else {
      setAmountString(amountString + key);
    }
  };

  const handlePercentPress = (percent: number) => {
    console.log(balance, percent, jettonPriceRatio);
    if (jettonPriceRatio && "jettonBuyRatio" in jettonPriceRatio) {
      setAmountString(
        `${Math.floor((balance * percent) / jettonPriceRatio.jettonBuyRatio / AMOUNT_STEP)}`,
      );
    }
  };

  const { mutateAsync: buyTonJetton } = useMutation({
    mutationFn: ({
      tonAmount,
      masterAddress,
      tgGroupId,
    }: {
      tonAmount: number;
      masterAddress: string;
      tgGroupId: number;
    }) => {
      if (tonAmount) {
        return axiosService.buyTonJetton({
          masterAddress,
          tonAmount,
          tgGroupId,
        });
      }
      return Promise.resolve({ status: 400, message: "[FE] Ton not found" });
    },
  });

  const reset = useCallback(() => {
    setAmountString(INITIAL_AMOUNT_STRING);
  }, [setAmountString]);

  const isInsufficientBalance = tempUsdAmount !== 0 && tempUsdAmount > +balance;

  return {
    usdAmount: tempUsdAmount,
    tokenAmount: +amountString * AMOUNT_STEP,
    tonAmount: tempTonAmount,
    shouldConfirmDisabled: isInsufficientBalance || isBuying,
    isProcessing: isBuying,
    onConfirm: handleConfirmClick,
    onNumpadPress: handleNumpadPress,
    onClose: handleOnClose,
    onPercentPress: handlePercentPress,
    priceHint: isInsufficientBalance && <PriceHint />,
    reset,
  };
};

export const useSellLogic = ({
  masterAddress,
  tokenQuantity,
  tgGroupId,
  onConfirm,
  onFail,
  onClose,
  setModalConfig,
}: {
  buyMinimum: number;
  masterAddress: string;
  tokenQuantity: number;
  tgGroupId: number;
  onConfirm?: (message?: string) => void;
  onFail?: (message?: string) => void;
  onClose?: () => void;
  setModalConfig?: (config: {
    variant: "success" | "error";
    title: string;
    message: string;
  }) => void;
}): TradeLogic => {
  const [amountString, setAmountString] = useState("0");
  const [isSelling, setIsSelling] = useState(false);
  const [tempUsdAmount, setTempUsdAmount] = useState(0);
  const [tempTonAmount, setTempTonAmount] = useState(0);

  const { data: jettonPriceRatio } = useQuery({
    queryKey: ["jetton-price", masterAddress],
    queryFn: () => axiosService.getNativePrice({ tokenAddress: masterAddress, chain: "ton" }),
    enabled: !!masterAddress,
    refetchInterval: 10 * 1000,
  });

  const { mutateAsync: sellTonJetton } = useMutation({
    mutationFn: ({
      jettonAmt,
      masterAddress,
      tgGroupId,
    }: {
      jettonAmt: number;
      masterAddress: string;
      tgGroupId: number;
    }) => axiosService.sellTonJetton({ masterAddress, jettonAmt, tgGroupId }),
  });

  useEffect(() => {
    if (jettonPriceRatio && "jettonSellRatio" in jettonPriceRatio) {
      const usdValue = jettonPriceRatio.jettonSellRatio * +amountString * AMOUNT_STEP;
      const tonValue = usdValue / jettonPriceRatio.nativePrice;
      setTempUsdAmount(usdValue);
      setTempTonAmount(tonValue);
    }
  }, [jettonPriceRatio, amountString]);

  const handleOnClose = () => {
    reset();
    onClose?.();
  };

  const isInsufficientTokenQuantity =
    +amountString !== 0 && +amountString * AMOUNT_STEP > tokenQuantity;

  const handlePercentPress = (percent: number) => {
    setAmountString(`${(tokenQuantity * percent) / AMOUNT_STEP}`);
  };

  const handleNumpadPress = (key: NumpadKey) => {
    if (key === "delete") {
      if (amountString.length > 1) {
        setAmountString(amountString.slice(0, -1));
      } else {
        setAmountString("0");
      }
    } else if (key === ".") {
      if (!amountString.includes(".")) {
        setAmountString(amountString + key);
      }
    } else {
      setAmountString(amountString + key);
    }
  };

  const handleConfirmClick = async () => {
    try {
      // TODO: confirm
      setIsSelling(true);
      const res = await sellTonJetton({
        jettonAmt: +amountString,
        masterAddress,
        tgGroupId,
      });
      if (res.status === 200) {
        setModalConfig?.({
          variant: "success",
          title: "Success",
          message: res.message,
        });
        reset();
        onConfirm?.(res.message);
      } else {
        setModalConfig?.({
          variant: "error",
          title: "Error",
          message: res.message,
        });
        onFail?.(res.message);
      }
    } catch (error) {
      console.error(error);
      onFail?.("Something went wrong. Please try again.");
    } finally {
      setIsSelling(false);
    }
  };

  const reset = useCallback(() => {
    setAmountString("0");
  }, [setAmountString]);

  return {
    usdAmount: tempUsdAmount,
    tokenAmount: +amountString * AMOUNT_STEP,
    tonAmount: tempTonAmount,
    shouldConfirmDisabled: isInsufficientTokenQuantity || isSelling,
    isProcessing: isSelling,
    onConfirm: handleConfirmClick,
    onNumpadPress: handleNumpadPress,
    onClose: handleOnClose,
    onPercentPress: handlePercentPress,
    priceHint: isInsufficientTokenQuantity && <PriceHint />,
    reset,
  };
};
