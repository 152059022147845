import { ColorType, CrosshairMode } from "lightweight-charts";
import { BasicChart, IChartConfig } from "./BasicChart";
import theme from "../../theme";

const configs: IChartConfig = {
  base: {
    layout: {
      background: {
        type: ColorType.Solid,
        color: theme.palette.surface.silver,
      },
      textColor: theme.palette.text["btn-dark"],
    },
    grid: {
      horzLines: {
        color: theme.palette.text["btn-dark"],
        visible: true,
      },
      vertLines: {
        visible: false,
      },
    },
    rightPriceScale: {
      visible: false,
    },
    leftPriceScale: {
      visible: false,
    },
    localization: {
      timeFormatter: (time: any) => {
        return time.toString();
      },
    },
    timeScale: {
      tickMarkFormatter: (time: any) => {
        return time.toString();
      },
      visible: false,
      fixLeftEdge: true,
      fixRightEdge: true,
      lockVisibleTimeRangeOnResize: true,
    },
    crosshair: {
      vertLine: {
        color: theme.palette.text["btn-dark"],
        width: 2,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        visible: false,
      },
      mode: CrosshairMode.Normal,
    },
    height: 0,
  },
  series: [
    {
      id: "jetton-price", // will affect the tooltip
      area: {
        lineColor: theme.palette.brand.primary,
        topColor: theme.palette.brand.primary,
        bottomColor: theme.palette.surface.silver,
        crosshairMarkerVisible: true,
      },
      line: {
        color: theme.palette.brand.primary,
        lineWidth: 2,
        crosshairMarkerRadius: 10,
        crosshairMarkerVisible: true,
        crosshairMarkerBorderColor: theme.palette.text["btn-dark"],
        crosshairMarkerBackgroundColor: theme.palette.surface.light,
        crosshairMarkerBorderWidth: 3,
      },
    },
  ],
};

export const TokenDetailChart = ({
  data,
  chartRef,
  seriesRef,
  setIsMounted,
  tooltip,
}: {
  data: Array<any>;
  chartRef: React.MutableRefObject<any>;
  seriesRef: React.MutableRefObject<any>;
  setIsMounted: (setIsMounted: boolean) => void;
  tooltip?: React.ReactNode;
}) => {
  return (
    <BasicChart
      data={data}
      configs={configs}
      chartRef={chartRef}
      seriesRef={seriesRef}
      setIsMounted={setIsMounted}
      tooltip={tooltip}
    />
  );
};
