import { Box, styled } from "@mui/material";
import SolanaIcon from "assets/icons/chains/solana.png";
import EthereumIcon from "assets/icons/chains/ethereum.png";
import TonIcon from "assets/icons/chains/ton.png";
import BaseIcon from "assets/icons/chains/base.png";

export type Chain = "solana" | "ethereum" | "ton" | "base";

const CHAIN_COLOR_MAP: Record<Chain, string> = {
  solana: "#232323",
  ethereum: "#6481E7",
  ton: "#338BC9",
  base: "#0052FF",
};

const CHAIN_BADGE_MAP: Record<Chain, string> = {
  solana: SolanaIcon,
  ethereum: EthereumIcon,
  ton: TonIcon,
  base: BaseIcon,
};

const CoinContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const TokenImage = styled("img")<{ chain?: Chain; size: "sm" | "md" }>(
  ({ theme, chain, size }) => ({
    width: size === "sm" ? theme.spacing(5) : theme.spacing(10),
    height: size === "sm" ? theme.spacing(5) : theme.spacing(10),
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "center",
    border: `1px solid ${chain ? CHAIN_COLOR_MAP[chain] : theme.palette.border.dark}`,
  }),
);

const ChainBadge = styled("img")<{ size: "sm" | "md" }>(({ theme, size }) => ({
  position: "absolute",
  top: size === "sm" ? theme.spacing(-0.1) : theme.spacing(-0.5),
  right: size === "sm" ? theme.spacing(-0.5) : theme.spacing(-0.5),
  width: size === "sm" ? theme.spacing(2) : theme.spacing(3),
  height: size === "sm" ? theme.spacing(2) : theme.spacing(3),
}));

type CoinAvatarProps = {
  iconUri: string;
  alt?: string;
  chain?: Chain;
  size?: "sm" | "md";
};

const CoinAvatar = ({ iconUri, alt, chain, size = "sm" }: CoinAvatarProps) => {
  return (
    <CoinContainer>
      <TokenImage src={iconUri} alt={alt} chain={chain} size={size} />
      {chain && <ChainBadge src={CHAIN_BADGE_MAP[chain]} alt={chain} size={size} />}
    </CoinContainer>
  );
};

export default CoinAvatar;
