import React from "react";
import { RetroCardContainer, RetroCardContent } from "./styled";

const RetroCard = ({
  children,
  size = "md",
  narrowOuter = false,
}: React.HTMLAttributes<HTMLDivElement> & { size?: "md" | "sm"; narrowOuter?: boolean }) => {
  return (
    <RetroCardContainer size={size} narrowOuter={narrowOuter}>
      <RetroCardContent size={size}>{children}</RetroCardContent>
    </RetroCardContainer>
  );
};

export { RetroCard };
