import { Box } from "@mui/material";
import { DefaultLoginBlockerContent, LoginBlockerWrapper } from "components/LoginBlocker";
import { RetroCard } from "components/RetroCard";
import tradeImage from "../../assets/icons/trade.png";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Portfolio } from "components/portfolio";

const LOGIN_REDIRECT_URL = process.env.REACT_APP_TG_WIDGET_LOGIN_URL;

const PortfolioPage = () => {
  const { tgUserId, tgUserName, walletAddresses } = useUserStoreV2();
  const { tonAddress } = walletAddresses || {};

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        window.open(LOGIN_REDIRECT_URL);
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  return (
    <LoginBlockerWrapper
      isLoginedIn={!!tgUserId || !!tgUserName || !!tonAddress}
      fallback={
        <RetroCard>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "20px",
            }}>
            <img src={tradeImage} alt="trade" />
            <Box
              sx={{
                textAlign: "center",
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "24px",
              }}>
              <DefaultLoginBlockerContent login={handleLogin} />
            </Box>
          </Box>
        </RetroCard>
      }>
      {/* TODO: Portfolio Page Body */}
      <Portfolio />
    </LoginBlockerWrapper>
  );
};

export { PortfolioPage };
