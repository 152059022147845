import { ReactNode } from "react";
import { useMutation } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { NumpadKey, TradeDrawerDataProps } from "components/TradeDrawer";
import { useEffect, useState } from "react";
import axiosService from "services/axios";
import PriceHint from "./priceHint";

const PREVIEW_REFETCH_INTERVAL = 1000 * 10;

export type TokenTradeDrawerDataProps = {
  balance: number;
  quantity: number;
  price: number;
  nativeSpent: number;
  tokenReceived: number;
  shouldConfirmDisabled: boolean;
  onConfirm: (slippage: number) => void;
  onNumpadPress: (key: NumpadKey) => void;
  onClose: () => void;
  onPercentPress: (percent: number) => void;
  priceHint: ReactNode;
  leftButton?: ReactNode;
};

export const useTradeLogic = ({
  srcToken,
  destToken,
  senderAddress,
  tradeType,
  tgGroupId,
  chain,
  onConfirm,
  onFail,
  onClose,
  setModalConfig,
}: {
  srcToken: string;
  destToken: string;
  senderAddress: string;
  tradeType: "BUY" | "SELL";
  tgGroupId: number;
  chain: string;
  onConfirm?: (message?: string) => void;
  onFail?: (message?: string) => void;
  onClose?: () => void;
  setModalConfig?: (config: {
    variant: "success" | "error";
    title: string;
    message: string;
  }) => void;
}): TokenTradeDrawerDataProps => {
  const [amountString, setAmountString] = useState("0");
  const [tempTokenAmount, setTempTokenAmount] = useState(0);
  const [nativeSpent, setNativeSpent] = useState(0);
  const [isBuying, setIsBuying] = useState(false);
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);

  // Init
  useEffect(() => {
    setAmountString("0");
    setTempTokenAmount(0);
    setNativeSpent(0);
    setIsBuying(false);
  }, [tradeType]);

  const {
    data: balanceData = {
      performance: null,
      profit: null,
      quantity: 0,
      usdValue: 0,
      balance: 0,
    },
  } = useQuery({
    queryKey: ["token-holders", srcToken],
    queryFn: () =>
      axiosService.getTokenBalance({
        tokenAddress: destToken!,
        walletAddress: senderAddress,
        chain: chain,
      }),
    enabled: !!destToken && !!senderAddress && !!chain,
  });

  const handleOnClose = () => {
    setAmountString("0");
    onClose?.();
  };

  const handleConfirmClick = async (slippage: number) => {
    try {
      setIsBuying(true);
      const res = await tokenTrade({
        srcToken: tradeType === "BUY" ? srcToken : destToken,
        destToken: tradeType === "BUY" ? destToken : srcToken,
        senderAddress,
        nativeSpent: tradeType === "BUY" ? nativeSpent : tempTokenAmount / 1000,
        priceUsd: +amountString,
        slippage,
        chain,
        tradeType,
        tgGroupId,
      });
      if (res.status === 200) {
        setModalConfig?.({
          variant: "success",
          title: "Success",
          message: "Your transaction is successful",
        });
        onConfirm?.(res.message);
      } else {
        setModalConfig?.({
          variant: "error",
          title: "Error",
          message: res.message,
        });
        onFail?.(res.message);
      }
    } catch (error) {
      console.error(error);
      onFail?.("Something went wrong. Please try again.");
    } finally {
      setIsBuying(false);
    }
  };

  const handleNumpadPress = (key: NumpadKey) => {
    if (key === "delete") {
      if (amountString.length > 1) {
        setAmountString(amountString.slice(0, -1));
      } else {
        setAmountString("0");
      }
    } else if (key === ".") {
      if (!amountString.includes(".")) {
        setAmountString(amountString + key);
      }
    } else {
      setAmountString(amountString + key);
    }
  };

  const handlePercentPress = (percent: number) => {
    if (tradeType === "BUY") {
      setAmountString(`${balanceData.balance * percent * tokenNativePrice.nativePrice}`);
    } else {
      setAmountString(`${balanceData.usdValue * percent}`);
    }
  };

  const { mutateAsync: tokenTrade } = useMutation({
    mutationFn: ({
      srcToken,
      destToken,
      senderAddress,
      nativeSpent,
      priceUsd: price,
      slippage,
      chain,
      tradeType,
      tgGroupId,
    }: {
      srcToken: string;
      destToken: string;
      senderAddress: string;
      nativeSpent: number;
      priceUsd: number;
      slippage: number;
      chain: string;
      tradeType: string;
      tgGroupId: number;
    }) => {
      if (
        srcToken &&
        destToken &&
        senderAddress &&
        nativeSpent &&
        price &&
        slippage &&
        chain &&
        tradeType &&
        tgGroupId
      ) {
        return axiosService.tokenTrade({
          srcToken,
          destToken,
          senderAddress,
          nativeSpent,
          priceUsd: price,
          slippage,
          chain,
          tradeType,
          tgGroupId,
        });
      }
      return Promise.resolve({ status: 400, message: "[FE] Ton not found" });
    },
  });

  const { data: tokenTradePreview, isLoading: isLoadingBuyTonPreview } = useQuery({
    queryKey: ["tokenTradePreview", amountString],
    queryFn: () =>
      axiosService.tokenTradePreview({
        priceUsd: +amountString,
        tokenAddress: destToken,
        chain,
      }),
    enabled: !!destToken && +amountString > 0,
    refetchInterval: PREVIEW_REFETCH_INTERVAL,
  });

  const { data: tokenNativePrice } = useQuery({
    queryKey: ["tokenNativePrice", amountString],
    queryFn: () =>
      axiosService.getNativePrice({
        tokenAddress: destToken,
        chain,
      }),
    enabled: !!destToken && !!chain,
    refetchInterval: PREVIEW_REFETCH_INTERVAL,
  });

  // Preview Flow
  useEffect(() => {
    if (!isLoadingBuyTonPreview) {
      setTempTokenAmount(tokenTradePreview?.tokenReceived ?? 0);
    }
  }, [tokenTradePreview, isLoadingBuyTonPreview]);

  // Native Price Flow
  useEffect(() => {
    if (tokenNativePrice) {
      if (tokenNativePrice && tokenNativePrice.nativePrice && +amountString) {
        const nativePrice = tokenNativePrice.nativePrice;
        const nativeSpent = +amountString / nativePrice;

        setNativeSpent(nativeSpent);
      }
    }
  }, [tokenNativePrice, amountString]);

  useEffect(() => {
    if (tradeType === "BUY") {
      setIsInsufficientBalance(+nativeSpent !== 0 && nativeSpent > +balanceData.balance);
    }
    if (tradeType === "SELL") {
      setIsInsufficientBalance(tempTokenAmount !== 0 && tempTokenAmount > +balanceData.quantity);
    }
  }, [nativeSpent, tempTokenAmount, balanceData, tradeType]);

  return {
    balance: balanceData?.balance ?? 0,
    quantity: balanceData?.quantity,
    price: +amountString,
    tokenReceived: tempTokenAmount,
    nativeSpent: nativeSpent ?? 0,
    shouldConfirmDisabled: isInsufficientBalance || isBuying,
    onConfirm: handleConfirmClick,
    onNumpadPress: handleNumpadPress,
    onClose: handleOnClose,
    onPercentPress: handlePercentPress,
    priceHint: isInsufficientBalance && <PriceHint />,
  };
};
