import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import axiosService from "services/axios";
import useToastStore from "store/toast-store/useToastStore";
import useTransactionStore from "store/transaction-store/useTransationStore";

const useMonitorTransaction = () => {
  const { transactionStore, resetTransaction } = useTransactionStore();
  const { showToast, resetToast, toastState } = useToastStore();
  const [count, setCount] = useState(0);

  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [
      "traceEvent",
      {
        masterAddress: transactionStore.tokenAddress,
        walletAddress: transactionStore.walletAddress,
      },
    ],
    queryFn: () => {
      setCount((prev) => prev + 1);
      return axiosService.traceEvent({
        masterAddress: transactionStore.tokenAddress ?? "",
        walletAddress: transactionStore.walletAddress ?? "",
      });
    },
    enabled: !!transactionStore.tokenAddress && !!transactionStore.walletAddress,
    refetchInterval: () => (count < 6 ? 5000 : false),
  });

  useEffect(() => {
    if (data?.status === 200 && toastState.variant !== "success") {
      showToast({ variant: "success", message: data?.message, duration: 3000 });
      resetTransaction();
      setCount(0);
      queryClient.invalidateQueries({
        queryKey: [
          "jetton-balance",
          {
            masterAddress: transactionStore.tokenAddress,
            walletAddress: transactionStore.walletAddress,
          },
        ],
      });
    } else if (
      data?.status &&
      data?.status >= 400 &&
      count >= 6 &&
      toastState.variant !== "error"
    ) {
      showToast({ variant: "error", message: data?.message, duration: 3000 });
      resetTransaction();
      setCount(0);
    } else {
      if (transactionStore.tokenAddress && transactionStore.walletAddress && !toastState.message) {
        showToast({ variant: "info", message: "Transaction is in progress" });
      }
    }
  }, [
    data,
    showToast,
    transactionStore,
    resetToast,
    toastState,
    resetTransaction,
    count,
    setCount,
    queryClient,
  ]);
};

export default useMonitorTransaction;
