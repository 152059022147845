import { Box, styled } from "@mui/material";

export const FullPageWrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  background: theme.palette.surface.bg,
  zIndex: 2,

  overflowY: "auto",
  overflowX: "hidden",

  "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
  "scrollbar-width": "none" /* Firefox */,

  "*::-webkit-scrollbar": {
    display: "none",
  },
  "*::-webkit-scrollbar-track": {
    display: "none",
  },
  "*::-webkit-scrollbar-thumb": {
    display: "none",
  },
}));

export const FullPageHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  height: "68px",
  width: "100%",
}));
