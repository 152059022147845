import { Box, Typography } from "@mui/material";
import { forwardRef } from "react";

export const LoadMoreRow = forwardRef((props, ref) => {
  return (
    <Box height="50px" display="flex" justifyContent="center" alignItems="center" ref={ref}>
      <Typography variant="body-default">Loading more...</Typography>
    </Box>
  );
});
