import { Box, Typography, useTheme } from "@mui/material";
import { CalledByImage, TokenCallRowContainer } from "./styled";
import { formatDistanceToNowStrict } from "date-fns";
import { Brick } from "components/Brick";
import { TokenCall } from "types";
import CoinAvatar, { Chain } from "components/CoinAvatar";
import { StyledLink } from "components/CabalGroup/styled";

type TokenCallRowProps = {
  tokenCall: TokenCall;
};

const TokenCallHeader = () => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Typography
        variant="pre-title-2"
        sx={{
          flex: 1,
        }}>
        Token
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          width: "80px",
        }}>
        Called by
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          width: "80px",
        }}>
        Confidence
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          width: "60px",
          textAlign: "right",
        }}>
        Time
      </Typography>
    </Box>
  );
};

const TokenCallRow = ({ tokenCall }: TokenCallRowProps) => {
  const { symbol, iconUri, chain, createdAt, confidenceInterval, jetton, address, groupId } =
    tokenCall;
  const theme = useTheme();

  return (
    <StyledLink href={`/token/${address}?groupId=${groupId}`}>
      <TokenCallRowContainer>
        <Box
          sx={{
            flex: 1,
            minWidth: "130px",
            display: "flex",
            gap: theme.spacing(1.5),
            alignItems: "center",
          }}>
          <CoinAvatar iconUri={iconUri} chain={chain as Chain | undefined} />
          <Typography
            variant="title-h3"
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {symbol}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "80px",
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
          }}>
          <CalledByImage src={jetton.imageUri} alt={jetton.name} />
        </Box>
        <Box sx={{ width: "60px", paddingX: theme.spacing(1) }}>
          <Brick variant="dark">
            <Typography variant="body-sm" color={theme.palette.text.secondary}>
              {confidenceInterval !== undefined ? `${confidenceInterval}%` : "N/A"}
            </Typography>
          </Brick>
        </Box>
        <Box sx={{ width: "80px", textAlign: "right" }}>
          <Typography
            variant="body-sm"
            color={theme.palette.text.secondary}
            sx={{
              whiteSpace: "nowrap",
            }}>
            {formatDistanceToNowStrict(
              typeof createdAt === "string" ? createdAt : createdAt * 1000,
              {
                addSuffix: true,
              },
            )
              .replace(" years", "y")
              .replace(" year", "y")
              .replace(" months", "mo")
              .replace(" month", "mo")
              .replace(" days", "d")
              .replace(" day", "d")
              .replace(" hours", "h")
              .replace(" minute", "m")
              .replace(" minutes", "m")
              .replace(" second", "s")
              .replace(" seconds", "s")}
          </Typography>
        </Box>
      </TokenCallRowContainer>
    </StyledLink>
  );
};

export { TokenCallRow, TokenCallHeader };
