import { Box, DrawerProps, styled, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { ReactNode, useRef, useState } from "react";
import { DarkDrawer, NumpadKey } from "components/TradeDrawer";
import RetroModal from "components/RetroModal";
import { useTradeLogic } from "./logic";
import { abbreviateNumber } from "utils";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Chain } from "components/CoinAvatar";
import { ReactComponent as XSquare } from "assets/icons/xsquare.svg";
import theme from "theme";
import TonIcon from "assets/icons/chains/ton.png";
import BaseIcon from "assets/icons/chains/base.png";
import EthIcon from "assets/icons/chains/ethereum.png";
import SolIcon from "assets/icons/chains/solana.png";
import { ReactComponent as Tune } from "assets/icons/tune.svg";
import { CircleButton } from "components/CircleButton";
import { Close } from "@mui/icons-material";
import { StyledInputV2 } from "components/form/input/styled";
import { WalletAddresses } from "store/user-store-v2";

const MIN_ENTRY_USD = 1;
const MAX_SLIPPAGE = 50;
const MIN_SLIPPAGE = 0;

const CHAIN_BADGE_MAP: Record<Chain, string> = {
  solana: SolIcon,
  ethereum: EthIcon,
  ton: TonIcon,
  base: BaseIcon,
};

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  useGrouping: false,
});

const CHAIN_WALLET_MAP: Record<Chain, keyof WalletAddresses> = {
  solana: "solAddress",
  ethereum: "ethAddress",
  ton: "tonAddress",
  base: "ethAddress",
};

const buttonConfig = [
  {
    label: "10%",
    value: 0.1,
  },
  {
    label: "25%",
    value: 0.25,
  },
  {
    label: "50%",
    value: 0.5,
  },
  {
    label: "MAX",
    value: 1,
  },
];

export const TokenTradeDrawer = (
  params: DrawerProps & { onClose: () => void } & {
    symbol: string;
    tokenQuantity: number;
    priceUsd: number;
    srcTokenAddress: string;
    destTokenAddress: string;
    tokenImageUrl: string;
    chain: Chain;
    groupId: number;
    onConfirm?: (message?: string) => void;
    onFail?: (message?: string) => void;
  },
) => {
  const theme = useTheme();
  const [mode, setMode] = useState<"BUY" | "SELL">("BUY");

  const [modalConfig, setModalConfig] = useState<{
    variant: "success" | "error";
    title: string;
    message: string;
  } | null>(null);

  const [shouldShowAmountTooSmall, setShouldShowAmountTooSmall] = useState(false);

  const [slippage, setSlippage] = useState(3);
  const [slippageMode, setSlippageMode] = useState<"MANUAL" | "AUTO">("AUTO");
  const [isOpenSlippageDrawer, setIsOpenSlippageDrawer] = useState(false);
  const slippageRef = useRef<HTMLInputElement>(null);

  const [isOpenConfirmDrawer, setIsOpenConfirmDrawer] = useState(false);
  const { walletAddresses } = useUserStoreV2();

  const {
    balance,
    quantity,
    price,
    tokenReceived,
    nativeSpent,
    shouldConfirmDisabled,
    priceHint,
    onConfirm: onConfirmTrade,
    onNumpadPress,
    onClose,
    onPercentPress,
  } = useTradeLogic({
    srcToken: params.srcTokenAddress,
    destToken: params.destTokenAddress,
    senderAddress: walletAddresses?.[CHAIN_WALLET_MAP[params.chain]] ?? "",
    tradeType: mode,
    tgGroupId: params.groupId,
    chain: params.chain,
    onConfirm: params.onConfirm,
    onFail: params.onFail,
    onClose: params.onClose,
    setModalConfig,
  });

  const handleConfirm = (slippage: number) => {
    if (mode === "BUY" && +price <= 0) {
      setShouldShowAmountTooSmall(true);
      return;
    }

    if (mode === "SELL" && quantity < tokenReceived) {
      setShouldShowAmountTooSmall(true);
      return;
    }

    onConfirmTrade?.(slippage);
    setIsOpenConfirmDrawer(false);
  };

  const handleOpenSlippageDrawer = () => {
    setIsOpenSlippageDrawer(true);
  };

  const handleSlippageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = parseInt(e.target.value);
      if (value !== MIN_SLIPPAGE && (!value || value < 0)) {
        if (slippageRef.current) {
          slippageRef.current.value = "0";
        }
        throw new Error("Invalid input");
      }

      if (value > MAX_SLIPPAGE) {
        if (slippageRef.current) {
          slippageRef.current.value = slippage.toString();
        }
        throw new Error("Invalid input");
      }

      if (slippageRef.current) {
        slippageRef.current.value = value.toString();
      }

      setSlippage(value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenConfirmDrawer = () => {
    setIsOpenConfirmDrawer(true);
  };

  return (
    <>
      <TradeDrawer
        nativeSpent={nativeSpent}
        symbol={params.symbol}
        tokenImageUrl={params.tokenImageUrl}
        chain={params.chain}
        open={params.open ?? false}
        handleOpenSlippageDrawer={handleOpenSlippageDrawer}
        setMode={setMode}
        mode={mode}
        onNumpadPress={onNumpadPress}
        onConfirm={handleOpenConfirmDrawer}
        shouldConfirmDisabled={shouldConfirmDisabled}
        onPercentPress={onPercentPress}
        tokenAmount={nativeSpent}
        price={price}
        tokenReceived={tokenReceived}
        onClose={onClose}
        balance={balance}
        quantity={quantity}
        priceHint={priceHint}
      />

      <DarkDrawer
        open={shouldShowAmountTooSmall}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">Amount is too small</Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            The minimum amount per deposit is ${MIN_ENTRY_USD}
          </Typography>
        </Box>
        <Box marginTop={theme.spacing(1)}>
          <RetroButton onClick={() => setShouldShowAmountTooSmall(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              OK
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      <DarkDrawer
        open={isOpenConfirmDrawer}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">CONFIRM</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
            marginBottom: "32px",
          }}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            Are you sure you want to make a payment of ${price}?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}>
          <RetroButton variant="white" onClick={() => setIsOpenConfirmDrawer(false)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              NO
            </Typography>
          </RetroButton>
          <RetroButton onClick={() => handleConfirm(slippage)}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              YES
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      <DarkDrawer
        open={isOpenSlippageDrawer}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography variant="title-h2">Slippage</Typography>
          <Box
            sx={{
              background: theme.palette.surface.container,
              borderRadius: theme.spacing(2),
              border: `1px solid ${theme.palette.border.dark}`,
              padding: theme.spacing(1),
              display: "flex",
              gap: theme.spacing(1),
            }}>
            <RetroButton
              onClick={() => setSlippageMode("MANUAL")}
              disabled={slippageMode === "MANUAL"}>
              <Box sx={{ padding: theme.spacing(1.5, 3) }}>
                <Typography variant="body-md">Manual</Typography>
              </Box>
            </RetroButton>
            <RetroButton onClick={() => setSlippageMode("AUTO")} disabled={slippageMode === "AUTO"}>
              <Box sx={{ padding: theme.spacing(1.5, 3) }}>
                <Typography variant="body-md">Auto</Typography>
              </Box>
            </RetroButton>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
          }}>
          {slippageMode === "AUTO" ? (
            <Typography variant="body-md" color={theme.palette.text.secondary}>
              Set the max price change during your trade.
            </Typography>
          ) : (
            <Box sx={{ position: "relative" }}>
              <StyledInputV2
                type="number"
                min="0"
                max="50"
                step="1"
                ref={slippageRef}
                defaultValue={slippage}
                onChange={handleSlippageChange}
              />
              <Typography
                variant="body-md"
                color={theme.palette.text.secondary}
                sx={{
                  position: "absolute",
                  right: "12px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}>
                %
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            marginTop: "32px",
          }}>
          <RetroButton
            variant="white"
            onClick={() => {
              if (slippageMode === "MANUAL") {
                setIsOpenSlippageDrawer(false);
              } else {
                setSlippage(3);
                setIsOpenSlippageDrawer(false);
              }
            }}>
            <Typography variant="title-h3" sx={{ paddingY: theme.spacing(1) }}>
              DONE
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>

      <RetroModal
        open={modalConfig !== null}
        onClose={() => {
          setModalConfig(null);
        }}
        variant={modalConfig?.variant ?? "success"}
        title={modalConfig?.title ?? ""}
        message={modalConfig?.message ?? ""}
      />
    </>
  );
};

const NumpadButton = styled("button")(({ theme }) => ({
  all: "unset",
  width: "33%",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textAlign: "center",

  background: "transparent",
  border: "none",
  cursor: "pointer",
}));

const TradeDrawer = ({
  open,
  chain,
  balance,
  quantity,
  nativeSpent,
  tokenImageUrl,
  symbol,
  setMode,
  handleOpenSlippageDrawer,
  mode,
  onNumpadPress,
  onConfirm,
  shouldConfirmDisabled,
  onPercentPress,
  tokenAmount,
  price,
  tokenReceived,
  onClose,
  priceHint,
}: {
  open: boolean;
  chain: Chain;
  balance: number;
  quantity: number;
  nativeSpent: number;
  tokenImageUrl: string;
  symbol: string;
  handleOpenSlippageDrawer: () => void;
  setMode: (mode: "BUY" | "SELL") => void;
  mode: "BUY" | "SELL";
  onNumpadPress: (key: NumpadKey) => void;
  onConfirm: () => void;
  shouldConfirmDisabled: boolean;
  onPercentPress: (value: number) => void;
  tokenAmount: number;
  price: number;
  tokenReceived: number;
  onClose: () => void;
  priceHint: ReactNode;
}) => {
  return (
    <DarkDrawer anchor="bottom" sx={{ zIndex: 100 }} open={open}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <CircleButton variant="white" size="lg" onClick={handleOpenSlippageDrawer}>
            <Tune />
          </CircleButton>
        </Box>
        <Box
          sx={{
            background: theme.palette.surface.container,
            borderRadius: theme.spacing(2),
            border: `1px solid ${theme.palette.border.dark}`,
            padding: theme.spacing(1),
            display: "flex",
            gap: theme.spacing(1),
          }}>
          <RetroButton onClick={() => setMode("SELL")} disabled={mode === "SELL"}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="body-md">Sell</Typography>
            </Box>
          </RetroButton>
          <RetroButton onClick={() => setMode("BUY")} disabled={mode === "BUY"}>
            <Box sx={{ padding: theme.spacing(1.5, 3) }}>
              <Typography variant="body-md">Buy</Typography>
            </Box>
          </RetroButton>
        </Box>
        <Box>
          <CircleButton variant="white" size="lg" onClick={onClose}>
            <Close />
          </CircleButton>
        </Box>
      </Box>

      <Box
        sx={{
          padding: theme.spacing(1, 2),
          gap: theme.spacing(1),
          display: "flex",
          background: theme.palette.surface.silver,
          borderRadius: theme.spacing(1.5),
          border: `1px solid ${theme.palette.border.dark}`,
          width: "fit-content",
          marginX: "auto",
          marginTop: theme.spacing(2),
          alignItems: "center",
        }}>
        {mode === "BUY" ? (
          <img src={CHAIN_BADGE_MAP[chain]} alt={symbol} width={16} height={16} />
        ) : (
          <img
            src={tokenImageUrl}
            alt={symbol}
            width={16}
            height={16}
            style={{ borderRadius: "50%" }}
          />
        )}
        <Typography variant="body-md" color={theme.palette.text.secondary}>
          Balance: {mode === "BUY" ? balance.toFixed(2) : abbreviateNumber(quantity)}
        </Typography>
      </Box>

      <Box sx={{ marginTop: theme.spacing(1), marginX: "auto", textAlign: "center" }}>
        <Box>
          <Typography variant="display-md">{numberFormatter.format(price)}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography
            variant="body-md"
            color={theme.palette.text.secondary}
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8px" }}>
            ≈{" "}
            {mode === "BUY"
              ? nativeSpent.toFixed(2)
              : tokenReceived > 1000
              ? abbreviateNumber(tokenReceived)
              : tokenReceived.toFixed(2)}{" "}
            {mode === "BUY" ? (
              <img
                src={CHAIN_BADGE_MAP[chain]}
                alt={symbol}
                width={16}
                height={16}
                style={{ margin: "auto" }}
              />
            ) : (
              <img
                src={tokenImageUrl}
                alt={symbol}
                width={16}
                height={16}
                style={{ borderRadius: "50%" }}
              />
            )}{" "}
            {/* ({numberFormatter.format(price)} USD) */}
          </Typography>
        </Box>
      </Box>
      {priceHint}
      <Box
        display={"flex"}
        gap={theme.spacing(1)}
        marginTop={theme.spacing(1)}
        width={"fit-content"}
        marginX={"auto"}>
        {buttonConfig.map((item) => (
          <RetroButton size="sm" key={item.label} onClick={() => onPercentPress(item.value)}>
            <Box sx={{ padding: theme.spacing(0.0, 2) }}>
              <Typography variant="body-default">{item.label}</Typography>
            </Box>
          </RetroButton>
        ))}
      </Box>

      <Box
        marginTop={theme.spacing(1)}
        display={"flex"}
        flexWrap={"wrap"}
        width={"100%"}
        marginX={"auto"}
        maxWidth={"600px"}>
        {Array.from({ length: 9 }).map((_, index) => (
          <NumpadButton key={index} onClick={() => onNumpadPress(`${index + 1}` as NumpadKey)}>
            <Typography variant="title-h1">{index + 1}</Typography>
          </NumpadButton>
        ))}
        <NumpadButton onClick={() => onNumpadPress(".")}>
          <Typography variant="title-h1">.</Typography>
        </NumpadButton>
        <NumpadButton onClick={() => onNumpadPress("0")}>
          <Typography variant="title-h1">0</Typography>
        </NumpadButton>
        <NumpadButton
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={() => onNumpadPress("delete")}>
          <XSquare />
        </NumpadButton>
      </Box>

      <RetroButton onClick={onConfirm} disabled={shouldConfirmDisabled}>
        <Box sx={{ padding: theme.spacing(1.5, 3) }}>
          <Typography variant="title-h3">{"CONFIRM"}</Typography>
        </Box>
      </RetroButton>
    </DarkDrawer>
  );
};
