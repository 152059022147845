import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { Telegram } from "@mui/icons-material";
import { RetroCard } from "components/RetroCard";

interface LoginBodyProps {
  login: () => void;
  message?: string;
}

export const DefaultLoginBlockerContent = ({
  login,
  message = "Login to continue the process",
}: LoginBodyProps) => {
  return (
    <>
      <Typography variant="display-md">CABAL.TOWN</Typography>
      <Typography variant="body-lg" color="text.secondary">
        {message}
      </Typography>
      <Box
        sx={{
          marginTop: "12px",
          height: "56px",
          maxWidth: "372px",
          width: "100%",
          paddingX: "12px",
        }}>
        <RetroButton id="telegram-login" onClick={login}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "12px",
              padding: "12px",
            }}>
            <Telegram />
            <Typography variant="title-h3">LOGIN VIA TELEGRAM</Typography>
          </Box>
        </RetroButton>
      </Box>
    </>
  );
};

// Wrapper for content that requires authentication
interface LoginBlockerWrapperProps {
  isLoginedIn: boolean;
  fallback?: ReactNode;
  children?: ReactNode;
}

export const LoginBlockerWrapper = ({
  isLoginedIn,
  fallback,
  ...props
}: LoginBlockerWrapperProps) => {
  return isLoginedIn ? <>{props.children}</> : <>{fallback}</>;
};

// Modal for blocking content that requires authentication
interface LoginBlockerModalProps {
  isLoginedIn: boolean;
  fallback?: ReactNode;
}

export const LoginBlockerModal = ({ isLoginedIn, fallback }: LoginBlockerModalProps) => {
  return isLoginedIn ? (
    <></>
  ) : (
    <>
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "2",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "3",
          padding: "86px 20px 49px",
          width: "100%",
          height: "100%",
        }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              gap: "10px",
            }}>
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                gap: "10px",
              }}>
              {fallback}
            </Box>
          </Box>
        </RetroCard>
      </Box>
    </>
  );
};
