import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Container, IconButton, Typography, useTheme } from "@mui/material";
import { FullPageHeader, FullPageWrapper } from "components/Fullpage";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { ROUTES } from "consts";
import { useParams } from "react-router-dom";
import { RetroCard } from "components/RetroCard";
import { useCallback, useEffect, useState } from "react";
import { RetroButton } from "components/RetroButton";
import { TokenTradeDrawer } from "components/TokenTradeDrawer";
import { PercentageBrick } from "components/PercentageBrick";
import { ContentCard } from "components/ContentCard";
import { ReactComponent as HelpIcon } from "assets/icons/warning/circle_help.svg";
import axiosService from "services/axios";
import { useQuery } from "@tanstack/react-query";
import { EmptyView } from "components/EmptyView";
import { LoadingView } from "components/LoadingView";

import SolanaIcon from "assets/icons/chains/solana.png";
import EthereumIcon from "assets/icons/chains/ethereum.png";
import TonIcon from "assets/icons/chains/ton.png";
import BaseIcon from "assets/icons/chains/base.png";
import CoinAvatar, { Chain } from "components/CoinAvatar";
import { format, formatDistanceStrict } from "date-fns";
import { useChart } from "hooks/useChart";
import { TokenPrice } from "types";
import { MouseEventParams, Time } from "lightweight-charts";
import { ChartTooltipContainer } from "components/Chart/ChartTooltip";
import { TokenDetailChart } from "components/Chart/TokenDetailChart";
import { useWsPriceData } from "./hook";
import { DepositModal, TokenType } from "components/Deposit";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { WalletAddresses } from "store/user-store-v2";

const ChartTooltip = ({
  x,
  y,
  data,
}: {
  x: number;
  y: number;
  data: { time: number; value: number };
}) => {
  return (
    <ChartTooltipContainer style={{ top: y + 24, left: x }}>
      <Box>
        <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>${data.value}</Box>
        <Box>{format(new Date(data.time), "dd/MM/yyyy")}</Box>
      </Box>
    </ChartTooltipContainer>
  );
};

const CHAIN_BADGE_MAP: Record<Chain, string> = {
  solana: SolanaIcon,
  ethereum: EthereumIcon,
  ton: TonIcon,
  base: BaseIcon,
};

const CHAIN_NATIVE_TOKEN_MAP: Record<Chain, TokenType> = {
  solana: "SOL",
  ethereum: "ETH",
  ton: "TON",
  base: "BASE",
};

const CHAIN_WALLET_MAP: Record<Chain, keyof WalletAddresses> = {
  solana: "solAddress",
  ethereum: "ethAddress",
  ton: "tonAddress",
  base: "ethAddress",
};

const TokenDetailsRenderer = ({ id }: { id: string | undefined }) => {
  const search = useLocation().search;
  const groupId = new URLSearchParams(search).get("groupId");
  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);
  const [timeInterval, setTimeInterval] = useState<"minute" | "hour" | "day" | "week">("hour");
  const [priceDataConsolidated, setPriceDataConsolidated] = useState<TokenPrice[]>([]);
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isReadyToTrade, setIsReadyToTrade] = useState(false);

  const [point, setPoint] = useState<{
    x: number;
    y: number;
    data: {
      time: number;
      value: number;
    };
  } | null>(null);

  const {
    chartRef,
    seriesRef,
    setIsMounted,
    isMounted,
    subscribeCrosshairMove,
    unsubscribeCrosshairMove,
  } = useChart();

  const { walletAddresses } = useUserStoreV2();

  const { data: tokenData, isLoading } = useQuery({
    queryKey: ["token", id],
    queryFn: () => axiosService.getToken({ tokenAddress: id! }),
    enabled: !!id,
  });

  const { data: priceData } = useQuery({
    queryKey: ["jetton-price", { chain: tokenData?.chain!, timeInterval }],
    queryFn: () =>
      axiosService.getCabalCallsPrice({
        pairAddress: tokenData?.pairAddress!,
        chain: tokenData?.chain!,
        interval: timeInterval,
      }),
    enabled: !!tokenData?.chain,
  });

  const { data: holdersData } = useQuery({
    queryKey: ["token-holders", id],
    queryFn: () => axiosService.getTokenHolders({ tokenAddress: id!, chain: tokenData?.chain! }),
    enabled: !!id && !!tokenData?.chain,
  });

  const { data: balanceData } = useQuery({
    queryKey: ["token-holders", id, tokenData],
    queryFn: () => {
      if (!id || !walletAddresses || !tokenData) return;
      return axiosService.getTokenBalance({
        tokenAddress: id,
        walletAddress: walletAddresses[CHAIN_WALLET_MAP[tokenData!.chain as Chain]]!,
        chain: tokenData.chain,
      });
    },
    enabled: !!id && !!tokenData,
  });

  useEffect(() => {
    if (priceData && balanceData) {
      setIsReadyToTrade(true);
    } else {
      setIsReadyToTrade(false);
    }
  }, [priceData, balanceData]);

  useEffect(() => {
    if (priceData) {
      setPriceDataConsolidated(priceData);
    }
  }, [priceData]);

  const transformPriceData = (data: TokenPrice) => {
    return {
      time: new Date(data.updatedAt).getTime(),
      value: data.price,
    };
  };

  const handleOnCrosshairMove = useCallback(
    (params: MouseEventParams<Time>) => {
      if (!params.point) {
        setPoint(null);
        return;
      }
      const series = seriesRef.current["jetton-price"]["area"];
      const data = params.seriesData.get(series) as {
        time: number;
        value: number;
      };

      if (!data || !data.value) {
        setPoint(null);
        return;
      }

      const y = series.priceToCoordinate(data.value);
      setPoint({ x: params.point.x, y, data });
    },
    [seriesRef],
  );

  useEffect(() => {
    if (isMounted) {
      subscribeCrosshairMove(handleOnCrosshairMove);
    }

    return () => {
      unsubscribeCrosshairMove(handleOnCrosshairMove);
    };
  }, [isMounted, subscribeCrosshairMove, unsubscribeCrosshairMove, handleOnCrosshairMove]);

  const handleOnPriceData = useCallback(
    (priceData: TokenPrice) => {
      setPriceDataConsolidated((prev) => {
        const last = prev.at(-1);

        // Same timestamp, update the last one
        if (last && last.updatedAt >= priceData.updatedAt) {
          prev[prev.length - 1] = priceData;
          return [...prev];
        }

        return [...prev, priceData];
      });
    },
    [setPriceDataConsolidated],
  );

  useWsPriceData({
    chain: tokenData?.chain!,
    masterAddress: tokenData?.tokenAddress!,
    onPriceData: handleOnPriceData,
  });

  const theme = useTheme();

  if (isLoading) {
    return <LoadingView />;
  }

  if (!tokenData) {
    return <EmptyView />;
  }

  return (
    <>
      <RetroCard>
        <Box
          sx={{
            padding: "20px 20px 0",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "21px",
            }}>
            <Box sx={{ width: "fit-content" }}>
              <CoinAvatar iconUri={tokenData.imageUrl} chain={tokenData.chain as Chain} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}>
              <Typography variant="pre-title" color="text.secondary">
                {tokenData.chain}
              </Typography>
              <Typography variant="title-h3">{tokenData.symbol}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "13px",
              padding: "12px 0 12px",
            }}>
            <Typography variant="display-sm">${tokenData.priceUsd}</Typography>
            <PercentageBrick percentage={tokenData?.performance ?? 0} />
          </Box>
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            padding: theme.spacing(2.5),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <Typography variant="title-h3">Chart</Typography>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "4/3",
              borderRadius: theme.spacing(3),
              overflow: "hidden",
            }}>
            <TokenDetailChart
              chartRef={chartRef}
              seriesRef={seriesRef}
              setIsMounted={setIsMounted}
              data={
                priceDataConsolidated.length ? priceDataConsolidated.map(transformPriceData) : []
              }
              tooltip={point ? <ChartTooltip x={point.x} y={point.y} data={point.data} /> : null}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              width: "100%",
              justifyContent: "center",
            }}>
            {[
              { label: "1m", value: "minute" },
              { label: "1h", value: "hour" },
              { label: "1d", value: "day" },
              { label: "1w", value: "week" },
            ].map((item) => (
              <RetroButton
                size="sm"
                onClick={() => setTimeInterval(item.value as "minute" | "hour" | "day" | "week")}
                disabled={timeInterval === item.value}>
                <Box paddingX={2}>
                  <Typography variant="body-default">{item.label}</Typography>
                </Box>
              </RetroButton>
            ))}
          </Box>
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            padding: theme.spacing(2.5),
          }}>
          <Typography variant="title-h3">
            your balance
            <HelpIcon />
          </Typography>

          <ContentCard title="USD Value" sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
              <Typography variant="title-h2">${(balanceData?.usdValue ?? 0).toFixed(2)}</Typography>
              <PercentageBrick percentage={balanceData?.performance ?? 0} />
            </Box>
          </ContentCard>
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Quantity" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {(balanceData?.quantity * 1000).toFixed(2) ?? 0}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="P&L" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {balanceData?.profit?.toFixed(2) ?? 0}
                  </span>
                  <span style={{ marginLeft: "4px" }}>USD</span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            padding: theme.spacing(2.5),
          }}>
          <Typography variant="title-h3">More Info</Typography>

          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Network" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                <img src={CHAIN_BADGE_MAP[tokenData.chain as Chain]} alt={tokenData.chain} />
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {tokenData.chain}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="Market Cap" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {tokenData.marketCap ?? 0}
                  </span>
                  <span style={{ marginLeft: "4px" }}>USD</span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>

          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Holders" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {holdersData?.length}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="Created At" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {formatDistanceStrict(tokenData.pairCreatedAt, new Date(), {
                      addSuffix: true,
                    })}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>

      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 3,
          paddingBottom: theme.spacing(1),
        }}>
        <RetroCard narrowOuter size="sm">
          <Box
            sx={{
              padding: theme.spacing(1, 1.5),
              height: "80px",
              display: "flex",
              gap: theme.spacing(1),
            }}>
            <RetroButton
              disabled={!isReadyToTrade}
              onClick={() => {
                if (!balanceData?.balance) {
                  setIsDepositModalOpen(true);
                } else {
                  setShouldTradeDrawerOpen(true);
                }
              }}>
              <Typography variant="title-h3">Trade</Typography>
            </RetroButton>
          </Box>
        </RetroCard>
      </Box>
      <TokenTradeDrawer
        open={shouldTradeDrawerOpen}
        tokenQuantity={balanceData?.quantity ?? 0}
        groupId={parseInt(groupId ?? "0")}
        priceUsd={parseFloat(tokenData.priceUsd)}
        srcTokenAddress={tokenData.srcToken}
        destTokenAddress={tokenData.destToken}
        tokenImageUrl={tokenData.imageUrl}
        symbol={tokenData.symbol}
        chain={tokenData.chain as Chain}
        onConfirm={() => setShouldTradeDrawerOpen(false)}
        onFail={() => setShouldTradeDrawerOpen(false)}
        onClose={() => setShouldTradeDrawerOpen(false)}
      />
      <DepositModal
        tokenType={CHAIN_NATIVE_TOKEN_MAP[tokenData.chain as Chain]}
        isOpen={isDepositModalOpen}
        close={() => setIsDepositModalOpen(false)}
      />
    </>
  );
};

const TokenIdPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <FullPageWrapper>
      <FullPageHeader>
        <Container>
          <Button sx={{ padding: "0", cursor: "pointer" }} onClick={() => navigate(ROUTES.call)}>
            <BackArrow />
            <Typography variant="button-md" color="text.secondary">
              Tokens
            </Typography>
          </Button>
        </Container>
      </FullPageHeader>
      <Container sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
        <TokenDetailsRenderer id={id} />
      </Container>
    </FullPageWrapper>
  );
};

export { TokenIdPage };
