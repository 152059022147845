import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { layoutConfigStateAtom } from "./index";

function useLayoutConfigStore() {
  const [state, setState] = useRecoilState(layoutConfigStateAtom);

  const setIsShowHeader = useCallback(
    (isShowHeader: boolean) => {
      setState((prevState) => {
        return {
          ...prevState,
          isShowHeader,
        };
      });
    },
    [setState],
  );

  const setIsShowFooter = useCallback(
    (isShowFooter: boolean) => {
      setState((prevState) => {
        return {
          ...prevState,
          isShowFooter,
        };
      });
    },
    [setState],
  );

  return {
    ...state,
    setIsShowHeader,
    setIsShowFooter,
  };
}

export default useLayoutConfigStore;
