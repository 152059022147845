import { useState } from "react";
import {
  CircleButtonContainer,
  CircleButtonInner,
  CircleButtonWrapper,
  Variant,
  Size,
} from "./styled";

const CircleButton = ({
  variant = "dark",
  shouldBounce = true,
  disabled = false,
  selected = false,
  animationDuration = 200,
  size = "sm",
  ...props
}: React.HTMLAttributes<HTMLButtonElement> & {
  variant?: Variant;
  shouldBounce?: boolean;
  disabled?: boolean;
  animationDuration?: number;
  selected?: boolean;
  size?: Size;
}) => {
  const [isClicking, setIsClicking] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsClicking(true);
    setTimeout(() => {
      props.onClick?.(e);
      if (shouldBounce) {
        setIsClicking(false);
      }
    }, animationDuration);
  };
  return (
    <CircleButtonWrapper>
      <CircleButtonContainer variant={variant} selected={selected} size={size}>
        <CircleButtonInner
          variant={variant}
          isClicking={isClicking}
          animationDuration={animationDuration}
          selected={selected}
          {...props}
          onClick={handleClick} // onClick override
        />
      </CircleButtonContainer>
    </CircleButtonWrapper>
  );
};

export { CircleButton };
