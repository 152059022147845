import { Box, Typography, useTheme } from "@mui/material";
import { CabalGroupRowContainer, OwnerImage, StyledLink } from "./styled";
import { CabalGroup, Jetton } from "types";
import { PercentageBrick } from "components/PercentageBrick";
import { Link } from "react-router-dom";

type CabalGroupRowProps = {
  jetton: Jetton;
};

const CabalGroupHeader = () => {
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      <Typography
        variant="pre-title-2"
        sx={{
          flex: 1,
        }}>
        Cabal Owner
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          width: "60px",
        }}>
        Win %
      </Typography>
      <Typography
        variant="pre-title-2"
        sx={{
          width: "110px",
          textAlign: "right",
        }}>
        7D Performance
      </Typography>
    </Box>
  );
};

const CabalGroupRow = ({ jetton }: CabalGroupRowProps) => {
  const {
    totalCalls,
    totalWins,
    day14Performance,
    day7Performance,
    day1Performance,
    tgGroupId,
    imageUri,
    name,
    language,
  } = jetton;
  const theme = useTheme();

  return (
    <StyledLink href={`/cabal/${tgGroupId}`}>
      <CabalGroupRowContainer>
        <Box
          sx={{
            flex: 1,
            minWidth: "130px",
            display: "flex",
            gap: theme.spacing(1.5),
            alignItems: "center",
          }}>
          <OwnerImage src={imageUri} alt={name} />
          <Typography
            variant="title-h3"
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "60px",
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
            justifyContent: "flex-end",
          }}>
          <Typography variant="body-default" color={theme.palette.text.secondary}>
            {totalWins}/{totalCalls}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "120px",
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <PercentageBrick percentage={day7Performance} />
        </Box>
      </CabalGroupRowContainer>
    </StyledLink>
  );
};

export { CabalGroupRow, CabalGroupHeader };
