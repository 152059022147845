import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { removeTokens, tgAuth } from "services/authV2";
import { userStateAtomV2 } from ".";

//TODO: Dev callback url
const DEV_CALLBACK_URL = process.env.REACT_APP_DEV_CALLBACK_URL;

function useUserStoreV2() {
  const [state, setState] = useRecoilState(userStateAtomV2);

  const logoutTgUserV2 = useCallback(() => {
    removeTokens();
    setState({
      userId: undefined,
      walletAddresses: {
        tonAddress: undefined,
        solAddress: undefined,
        ethAddress: undefined,
      },
      tgUserId: undefined,
      tgUserName: undefined,
      tgPhotoUrl: undefined,
    });
  }, [setState]);

  const getTgUserIdV2 = useCallback(async () => {
    try {
      // (1): Try to get tgUserId from window app
      await _getTgUserIdByWindowApp();
    } catch (error) {
      try {
        console.info(error);
        // (2): Try to get tgUserId from callback
        await _getTgUserIdFromCallback();
      } catch (error) {
        console.info(error);
      }
    }
  }, [setState]);

  const _getTgUserIdByWindowApp = useCallback(async () => {
    if (window.Telegram.WebApp) {
      // Initialize the Web App
      window.Telegram.WebApp.ready();
      console.debug(
        "window.Telegram.WebApp.initDataUnsafe: ",
        window.Telegram.WebApp.initDataUnsafe,
      );

      // Get user information
      const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
      const user = initDataUnsafe.user;
      const userId = user?.id;

      if (userId) {
        const { user } = await tgAuth({
          userData: {
            user: {
              id: parseInt(userId),
            },
          },
        });

        const { id, tgUserId, tgUserName, tonAddress, solAddress, ethAddress, tgPhotoUrl } = user;
        console.log(
          await tgAuth({
            userData: {
              user: {
                id: parseInt(tgUserId),
              },
            },
          }),
        );

        setState((prevState) => {
          return {
            ...prevState,
            userId: id,
            walletAddresses: {
              tonAddress,
              solAddress,
              ethAddress,
            },
            tgUserId,
            tgUserName,
            tgPhotoUrl,
          };
        });
      } else {
        throw new Error("Failed to get tgUserId from window app");
      }
    }
  }, [setState]);

  const _getTgUserIdFromCallback = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search || DEV_CALLBACK_URL);

    const _tgUserId = urlParams.get("tgUserId");
    const _tgToken = urlParams.get("token");

    if (!_tgUserId || !_tgToken) {
      console.debug("initDataUnsafe", {
        _tgUserId,
        _tgToken,
      });
      throw new Error("Failed to get tgUserId from Callback");
    }

    const userId = decodeURI(_tgUserId);

    if (userId) {
      const { user } = await tgAuth({
        userData: {
          user: {
            id: parseInt(userId),
          },
        },
      });

      const { id, tgUserId, tgUserName, tonAddress, solAddress, ethAddress, tgPhotoUrl } = user;

      setState((prevState) => {
        return {
          ...prevState,
          userId: id,
          walletAddresses: {
            tonAddress,
            solAddress,
            ethAddress,
          },
          tgPhotoUrl,
          tgUserId,
          tgUserName,
        };
      });
    } else {
      throw new Error("Failed to get tgUserId from callback");
    }
  }, [setState]);

  return {
    ...state,
    getTgUserIdV2,
    logoutTgUserV2,
  };
}

export default useUserStoreV2;
