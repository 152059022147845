import React, { forwardRef, useRef, useState, ChangeEvent, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import {
  CabalIcon,
  CreateCabalButtonWrapper,
  Curve,
  CurveSteepnessHeaderWrapper,
  CurveSteepnessOption,
  CurveSteepnessOptionRadio,
  CurveSteepnessOptionRadioContainer,
  CurveSteepnessOptionsWrapper,
  CurveSteepnessOptionText,
  StyledInput,
  StyledLabel,
  StyledOption,
  StyledSelect,
  UploadButtonWrapper,
  UploadImageContainer,
  ViewButtonWrapper,
} from "./styles";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { SteepnessPreview } from "./steepnessPreview/SteepnessPreview";
import { useNetwork } from "lib/hooks/useNetwork";
import axiosService from "services/axios";
import { CreateCabalError, CreateCabalSuccess } from "./createCabalModal";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { ROUTES } from "consts";
import { PreviewChart } from "components/Chart/PreviewChart";

const initialData = {
  Casual: require("./data/casual.json"),
  Standard: require("./data/standard.json"),
  Exclusive: require("./data/exclusive.json"),
};

// Define the ref type
type CreateCabalData = {
  cabalName: string;
  cabalSize: number;
  cabalImageUrl: string;
};
export interface CreateCabalRef {
  getValue: () => CreateCabalData;
  deployContract: () => Promise<void>;
}

export type Steepness = "Casual" | "Standard" | "Exclusive";
export type Language = "English" | "Chinese" | "Korean";

type CreateCabalProps = {};

const tonnetworks = {
  mainnet: "ton",
  testnet: "ton_testnet",
};

const SteepnessRR = {
  Casual: "7500",
  Standard: "420",
  Exclusive: "190",
};

const SteepnessMaxMemberPreview = {
  Casual: "2000",
  Standard: "200",
  Exclusive: "100",
};

const LanguageShortForm = {
  English: "en",
  Chinese: "zh_hk",
  Korean: "ko",
};

export const CreateCabalV2 = forwardRef<CreateCabalRef, CreateCabalProps>((props, ref) => {
  const { tgUserId, tgUserName, walletAddresses, tgPhotoUrl } = useUserStoreV2();
  const { network } = useNetwork();
  const navigate = useNavigatePreserveQuery();

  // Icon
  const [icon, setIcon] = useState<File | undefined>();
  const cabalIconRef = useRef<HTMLInputElement>(null);

  // Name, Token ticker, Language
  const [name, setName] = useState<string | undefined>();
  const [tokenTicker, setTokenTicker] = useState<string | undefined>();
  const [language, setLanguage] = useState<Language>("English");

  // Curve Steepness
  const [curveSteepness, setCurveSteepness] = useState<Steepness>("Standard");

  // Submition
  const [isValidToSubmit, setIsValidToSubmit] = useState<boolean>(false);

  // Preview
  const [isOpennedPreview, setIsOpennedPreview] = useState(false);

  // Drawer
  const [isOpennedModal, setIsOpennedModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Created cabal id
  const [cabalId, setCabalId] = useState<string | undefined>();

  useEffect(() => {
    if (name && tokenTicker && language && curveSteepness) {
      setIsValidToSubmit(true);
    } else {
      setIsValidToSubmit(false);
    }
  }, [icon, name, tokenTicker, language, curveSteepness]);

  const handleUpload = () => {
    cabalIconRef.current?.click();
  };

  const handleIconSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIcon(file);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTokenTickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTokenTicker(event.target.value);
  };

  const handleSelectLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleOpenPreview = () => {
    setIsOpennedPreview(true);
  };

  const handleClosePreview = () => {
    setIsOpennedPreview(false);
  };

  const handleOpenSuccessModal = () => {
    setErrorMessage("");
    setIsOpennedModal(true);
  };

  const handleOpenErrorModal = (message: string) => {
    setErrorMessage(message);
    setIsOpennedModal(true);
  };

  const handleCloseModal = () => {
    setIsOpennedModal(false);
  };

  const handleSelectCurveSteepness = (steepness: Steepness) => {
    setCurveSteepness(steepness);
  };

  const handleDone = async () => {
    if (!cabalId) return;
    navigate(ROUTES.cabalId.replace(":id", cabalId));
  };

  // Form submition
  const handleOnSubmit = async () => {
    try {
      if (
        !network ||
        !name ||
        !tgUserId ||
        !tokenTicker ||
        !walletAddresses ||
        !walletAddresses.tonAddress ||
        !tgUserName ||
        !curveSteepness ||
        !tokenTicker ||
        !language
      ) {
        throw new Error("Failed to create cabal.");
      }

      let iconUrl;
      if (icon) {
        iconUrl = await axiosService.uploadFile(icon);
      } else {
        iconUrl = tgPhotoUrl;
      }

      if (!iconUrl) {
        handleOpenErrorModal("Failed to upload image.");
        return;
      }

      const body = {
        image: iconUrl,
        chain: tonnetworks[network] as "ton" | "ton_testnet",
        name: name,
        tgUserId: tgUserId,
        tgUserName: tgUserName,
        reserveRate: SteepnessRR[curveSteepness],
        description: "Cabal",
        symbol: tokenTicker || "",
        decimals: "9",
        language: LanguageShortForm[language],
      };

      const response = await axiosService.insertJettonV2(body);

      const { tgGroupId } = response.res;

      if (response.status > 299) {
        throw new Error(response.res.message);
      } else {
        console.log("Cabal created with id:", tgGroupId);
      }

      setCabalId(tgGroupId);
      handleOpenSuccessModal();
    } catch (error) {
      console.error("Failed to create cabal:", error);
      setCabalId(undefined);
      handleOpenErrorModal("Failed to create cabal.");
    }
  };

  return (
    <RetroCard>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
          gap: "16px",
        }}>
        <UploadImageContainer>
          <CabalIcon src={icon ? URL.createObjectURL(icon) : tgPhotoUrl}>
            <ImageIcon />
          </CabalIcon>
          <UploadButtonWrapper>
            <RetroButton variant="white" onClick={handleUpload}>
              <Typography variant="title-h3">{icon ? "CHANGE" : "UPLOAD"}</Typography>
            </RetroButton>
            <input
              ref={cabalIconRef}
              type="file"
              onChange={handleIconSelected}
              accept="image/png, image/jpeg"
              hidden
            />
          </UploadButtonWrapper>
        </UploadImageContainer>
        <StyledLabel>
          <Typography variant="body-md" color="text.secondary">
            Name
          </Typography>
          <StyledInput id="name" placeholder="Full name" onChange={handleNameChange} />
        </StyledLabel>
        <StyledLabel>
          <Typography variant="body-md" color="text.secondary">
            Token ticker
          </Typography>
          <StyledInput id="name" placeholder="Sort name" onChange={handleTokenTickerChange} />
        </StyledLabel>
        <StyledLabel>
          <Typography variant="body-md" color="text.secondary">
            Language
          </Typography>
          <StyledSelect id="name" defaultValue="English" onChange={handleSelectLanguage} required>
            <StyledOption value="English">English</StyledOption>
            <StyledOption value="Chinese">中文</StyledOption>
            <StyledOption value="Korean">한국어</StyledOption>
          </StyledSelect>
        </StyledLabel>
        <CurveSteepnessHeaderWrapper>
          <Typography variant="body-md" color="text.secondary">
            Curve steepness
          </Typography>
          <ViewButtonWrapper>
            <RetroButton variant="white" onClick={handleOpenPreview}>
              <Typography variant="button-md">PREVIEW</Typography>
            </RetroButton>
          </ViewButtonWrapper>
        </CurveSteepnessHeaderWrapper>
        <CurveSteepnessOptionsWrapper>
          {(["Casual", "Standard", "Exclusive"] as Steepness[]).map((option) => (
            <CurveSteepnessOptionRadioContainer
              $selected={curveSteepness === option}
              key={option}
              onClick={() => handleSelectCurveSteepness(option as Steepness)}>
              <CurveSteepnessOption>
                {/* <Curve $variant={option.toLowerCase() as "casual" | "standard" | "exclusive"} /> */}
                <PreviewChart
                  variant={curveSteepness === option ? "active" : "non-active"}
                  data={initialData[option as Steepness].slice(
                    0,
                    SteepnessMaxMemberPreview[option],
                  )}
                />
                <CurveSteepnessOptionText variant="body-md">{option}</CurveSteepnessOptionText>
              </CurveSteepnessOption>
              <CurveSteepnessOptionRadio />
            </CurveSteepnessOptionRadioContainer>
          ))}
        </CurveSteepnessOptionsWrapper>
        <SteepnessPreview
          isOpenned={isOpennedPreview}
          close={handleClosePreview}
          selectedSteepness={curveSteepness}
          handleSelectCurveSteepness={handleSelectCurveSteepness}
        />
        {errorMessage === "" ? (
          <CreateCabalSuccess isOpen={isOpennedModal} close={handleCloseModal} done={handleDone} />
        ) : (
          <CreateCabalError
            isOpen={isOpennedModal}
            close={handleCloseModal}
            errorMessage={errorMessage}
          />
        )}
        <CreateCabalButtonWrapper $valid={isValidToSubmit} onClick={handleOnSubmit}>
          <RetroButton variant={isValidToSubmit ? "primary" : "disabled"}>
            <Typography
              variant="title-h3"
              color={isValidToSubmit ? "text.brand" : "text.secondary"}>
              Create Cabal
            </Typography>
          </RetroButton>
        </CreateCabalButtonWrapper>
      </Box>
    </RetroCard>
  );
});
