import { RetroCard } from "components/RetroCard";
import { CheckCircleImage, ModalWrapper, Overlay } from "./styled";
import { Box, Typography } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import chekcCircleImage from "../../../assets/icons/check-circle.png";
import errorCircleImage from "../../../assets/icons/error-circle.png";
import { ReactNode } from "react";

interface CreateCabalSuccessProps {
  type: "Success" | "Error";
  description: string;
  isOpen: boolean;
  close: () => void;
  children?: ReactNode;
}

// TODO: Refactor into styled-components
export const CreateCabalModal = ({
  isOpen,
  close,
  type,
  description,
  children,
}: CreateCabalSuccessProps) => {
  return isOpen ? (
    <Overlay>
      <ModalWrapper>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: "20px",
              gap: "16px",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}>
              <CircleButton variant="white" onClick={close}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  X
                </Box>
              </CircleButton>
            </Box>
            {type === "Success" ? (
              <CheckCircleImage src={chekcCircleImage} alt="check-circle" />
            ) : (
              <CheckCircleImage src={errorCircleImage} alt="check-circle" />
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginX: "auto",
                textAlign: "center",
                width: "300px",
                gap: "16px",
              }}>
              <Typography variant="title-h1">{type}</Typography>
              <Typography variant="body-lg" color="text.secondary">
                {description}
              </Typography>
            </Box>
            {children}
          </Box>
        </RetroCard>
      </ModalWrapper>
    </Overlay>
  ) : (
    <></>
  );
};
