import { Close } from "@mui/icons-material";
import { Box, Modal, ModalProps, styled, Typography, useTheme } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import { RetroCard } from "components/RetroCard";

import chekcCircleImage from "assets/icons/check-circle.png";
import errorCircleImage from "assets/icons/error-circle.png";

const ModalWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  padding: theme.spacing(1),
  maxWidth: "800px",
}));

const RetroModal = (
  props: Omit<ModalProps, "children"> &
    (
      | {
          variant: "success" | "error";
          title: string;
          message: string;
        }
      | {
          children: ModalProps["children"];
        }
    ),
) => {
  const theme = useTheme();

  return (
    <Modal {...props}>
      <ModalWrapper>
        <RetroCard>
          {"variant" in props ? (
            <Box sx={{ padding: theme.spacing(2.5) }}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <CircleButton
                  variant="white"
                  size="lg"
                  onClick={() => {
                    props.onClose?.({}, "backdropClick");
                  }}>
                  <Close />
                </CircleButton>
              </Box>
              <Box display={"flex"} justifyContent={"center"} marginTop={theme.spacing(3)}>
                <img
                  src={props.variant === "success" ? chekcCircleImage : errorCircleImage}
                  alt="icon"
                />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                textAlign={"center"}
                marginTop={theme.spacing(3)}>
                <Typography variant="title-h1">{props.title}</Typography>
                <Box marginTop={theme.spacing(1)}>
                  <Typography variant="body-lg" color={theme.palette.text.secondary}>
                    {props.message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            props.children
          )}
        </RetroCard>
      </ModalWrapper>
    </Modal>
  );
};

export default RetroModal;
