import { IChartApi, ISeriesApi, MouseEventParams, Time } from "lightweight-charts";
import { useRef, useState } from "react";

export const useChart = () => {
  const chartRef = useRef<IChartApi | null>(null);

  const seriesRef = useRef<any>();
  const tooltipRef = useRef<any>(); // NOTE: required passing a Tooltip component into BasicChart component
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [isMounted, setIsMounted] = useState(false);

  const updateCrosshairPosition = (
    x: number,
    y: number,
    series: ISeriesApi<"Area", Time> | ISeriesApi<"Line", Time>,
  ) => {
    if (!chartRef.current) return;
    chartRef.current.setCrosshairPosition(x, y as Time, series);
  };

  const subscribeCrosshairMove = (callback: (param: MouseEventParams<Time>) => void) => {
    if (!chartRef.current) return;
    chartRef.current.subscribeCrosshairMove((param: MouseEventParams<Time>) => callback(param));
  };

  const unsubscribeCrosshairMove = (callback: (param: MouseEventParams<Time>) => void) => {
    if (!chartRef.current) return;
    chartRef.current.unsubscribeCrosshairMove((param: MouseEventParams<Time>) => callback(param));
  };

  return {
    chartRef,
    seriesRef,
    chartContainerRef,
    tooltipRef,
    isMounted,
    updateCrosshairPosition,
    subscribeCrosshairMove,
    unsubscribeCrosshairMove,
    setIsMounted,
  };
};
