import { Box, styled } from "@mui/material";

export const ChartTooltipContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  background: theme.palette.surface.light,
  border: `1px solid ${theme.palette.border.dark}`,

  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 3,

  transform: "translate(-50%, 0)",

  maxWidth: "120px",
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",

  color: theme.palette.text["btn-dark"],
  ...theme.typography["button-lg"],
}));
